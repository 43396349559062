import React, { useState } from "react";
import { Typography, Tabs, Tab, Paper, TextField, Menu, MenuItem, ListItemIcon, ListItemText } from "@material-ui/core";
import Button from "../UI/Button/Button";
import PharmacyItem from "../UI/User/PharmacyItem";
import IconCheck from "../UI/Icons/IconCheck";
import IconDown from "../UI/Icons/IconDown";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Paper p={3}>{children}</Paper>}
    </div>
  );
}

const UserOriginal = () => {
  const [newPharmacy, setNewPharmacy] = useState(false);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = useState(0);
  const [editPharmacy, setEditPharmacy] = useState(false);

  const handleEditPharmacyClick = () => {
    setEditPharmacy(!editPharmacy);
  };

  const handleNewPharmacyClick = () => {
    setNewPharmacy(!newPharmacy);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [districtMenu, setDistrictMenu] = useState(null);
  const handleDistrictMenuClick = (event) => {
    event.preventDefault();
    setDistrictMenu(event.currentTarget);
  };
  const handleDistrictMenuClose = () => {
    setDistrictMenu(null);
  };

  const [cityMenu, setCityMenu] = useState(null);
  const handleCityMenuClick = (event) => {
    event.preventDefault();
    setCityMenu(event.currentTarget);
  };
  const handleCityMenuClose = () => {
    setCityMenu(null);
  };

  const [pharmacyMenu, setPharmacyMenu] = useState(null);
  const handlePharmacyMenuClick = (event) => {
    event.preventDefault();
    setPharmacyMenu(event.currentTarget);
  };
  const handlePharmacyMenuClose = () => {
    setPharmacyMenu(null);
  };

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  console.log("editPharmacy", editPharmacy);

  return (
    <div className="container">
      {newPharmacy === false ? (
        <>
          <Typography component="h5" variant="h5">
            Uživatel
          </Typography>
          {value === 1 ? (
            <div className="addPharmacy" onClick={handleNewPharmacyClick}>
              <Button variant="contained" class="rounded icon white" icon="plus" iconColor="rgba(0, 0, 0, 0.54)" />
              <Button text="Přidat novou lékárnu" class="white regular" variant="contained" />
            </div>
          ) : null}
          <Tabs className="tabHeader" value={value} indicatorColor="secondary" textColor="secondary" onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Úživatelský účet" {...a11yProps(0)} />
            <Tab label="lékárny" {...a11yProps(1)} />
          </Tabs>

          <TabPanel className="tabBodyUser noShadow" value={value} index={0}>
            <div className="user">
              <Typography component="h6" variant="h6">
                Základní údaje
              </Typography>

              <div className="userForm">
                <div className="formLine">
                  <TextField required id="name" label="Jméno" defaultValue="Hello World" variant="outlined" />
                  <TextField required id="surname" label="Přijmení" defaultValue="Hello World" variant="outlined" />
                </div>

                <div className="formLine">
                  <TextField required type="email" id="email" label="Email" defaultValue="Hello World" variant="outlined" />
                  <TextField required id="phone" label="Telefon" defaultValue="Hello World" variant="outlined" />
                </div>

                <Button text="Uložit změny" class="orange icon iconSeparate" iconColor="white" icon="edit" variant="contained" />
              </div>

              <Typography component="h6" variant="h6">
                Změna hesla
              </Typography>

              <div className="userForm">
                <div className="passwordLine">
                  <TextField required type="password" id="oldPassword" label="původní heslo" defaultValue="Hello World" variant="outlined" />
                  <TextField required type="password" id="newPassword" label="nové heslo" defaultValue="Hello World" variant="outlined" />
                  <TextField required type="password" id="recapPassword" label="potvrzení nového hesla" defaultValue="Hello World" variant="outlined" />
                </div>
                <Button text="změnit heslo" class="orange icon iconSeparate" iconColor="white" icon="trash" variant="contained" />
              </div>
            </div>
          </TabPanel>

          <TabPanel className={`tabBodyPharmacy ${editPharmacy ? "white" : "noShadow"}`} value={value} index={1}>
            {editPharmacy === false ? (
              <>
                <PharmacyItem editPharmacy={handleEditPharmacyClick} />
                <PharmacyItem selected />
                <PharmacyItem />
                <PharmacyItem deleted />
              </>
            ) : (
              <div className="editPharmacy">
                <div className="editPharmacyHead">
                  <Typography component="h6" variant="h6">
                    Základní údaje
                  </Typography>
                  <Button text="ukončit úpravy" variant="contained" onClick={handleEditPharmacyClick} class="orange" />
                </div>
                <div className="userForm">
                  <div className="formLine">
                    <TextField required id="phoneNumber1" label="telefon 1" defaultValue="+420 752 345 424" variant="outlined" />
                    <TextField required id="phoneNumber2" label="telefon 2" defaultValue="+420 752 345 424" variant="outlined" />
                  </div>

                  <div className="formLine">
                    <TextField required id="phoneNumber3" label="telefon 3" defaultValue="+420 752 345 424" variant="outlined" />
                    <TextField required type="email" id="pharmacyEmail" label="Email" defaultValue="lekarna@gmail.com" variant="outlined" />
                  </div>

                  <div className="formLine">
                    <div>
                      <Typography component="h6" variant="h6">
                        Kopie registrace lékárny z krajského úřadu
                      </Typography>
                      <input accept="image/*" id="contained-button-file" multiple type="file" style={{ display: "none" }} />
                      <label htmlFor="contained-button-file">
                        <Button variant="contained" color="primary" text="Vyberte soubor" component="span">
                          Upload
                        </Button>
                      </label>
                    </div>
                    <TextField required type="text" id="preferredDistributor" className="preferred" label="Preferovaný distributor" defaultValue="PHOENIX" variant="outlined" />
                  </div>
                </div>
              </div>
            )}
          </TabPanel>
        </>
      ) : (
        <>
          <div className="newPharmacyPage">
            <div className="newPharmacyHead">
              <Typography component="h5" variant="h5">
                Nová lékárna
              </Typography>
              <Button text="zrušit" variant="contained" class="orange" onClick={handleNewPharmacyClick} />
            </div>
            <Paper className="newPharmacy">
              <div className="newPharmacyForm">
                <div className="formLine">
                  <div className="selectButton">
                    <IconDown color="#000" class={districtMenu ? "selected" : ""} />
                    <TextField required id="pharmacyDistrict" label="Okres" defaultValue="Bánovce nad Bebravou" variant="outlined" onClick={handleDistrictMenuClick} />
                  </div>
                  <Menu
                    id="customized-menu"
                    anchorEl={districtMenu}
                    keepMounted
                    open={Boolean(districtMenu)}
                    onClose={handleDistrictMenuClose}
                    elevation={0}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <MenuItem className="menuItem" onClick={(event) => handleListItemClick(event, 0)}>
                      <ListItemText primary="Lékárna 1" />
                      {selectedIndex === 0 ? (
                        <ListItemIcon>
                          <IconCheck color="#fff" />
                        </ListItemIcon>
                      ) : null}
                    </MenuItem>
                    <MenuItem className="menuItem" onClick={(event) => handleListItemClick(event, 1)}>
                      <ListItemText primary="Lékárna 2" />
                      {selectedIndex === 1 ? (
                        <ListItemIcon>
                          <IconCheck color="#fff" />
                        </ListItemIcon>
                      ) : null}
                    </MenuItem>
                    <MenuItem className="menuItem" onClick={(event) => handleListItemClick(event, 2)}>
                      <ListItemText primary="Lékárna 3" />
                      {selectedIndex === 2 ? (
                        <ListItemIcon>
                          <IconCheck color="#fff" />
                        </ListItemIcon>
                      ) : null}
                    </MenuItem>
                  </Menu>

                  <div className="selectButton">
                    <IconDown color="#000" class={cityMenu ? "selected" : ""} />
                    <TextField required id="pharmacyDistrict" label="Okres" defaultValue="Bánovce nad Bebravou" variant="outlined" onClick={handleCityMenuClick} />
                  </div>
                  <Menu
                    id="customized-menu"
                    anchorEl={cityMenu}
                    keepMounted
                    open={Boolean(cityMenu)}
                    onClose={handleCityMenuClose}
                    elevation={0}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <MenuItem className="menuItem" onClick={(event) => handleListItemClick(event, 0)}>
                      <ListItemText primary="Lékárna 1" />
                      {selectedIndex === 0 ? (
                        <ListItemIcon>
                          <IconCheck color="#fff" />
                        </ListItemIcon>
                      ) : null}
                    </MenuItem>
                    <MenuItem className="menuItem" onClick={(event) => handleListItemClick(event, 1)}>
                      <ListItemText primary="Lékárna 2" />
                      {selectedIndex === 1 ? (
                        <ListItemIcon>
                          <IconCheck color="#fff" />
                        </ListItemIcon>
                      ) : null}
                    </MenuItem>
                    <MenuItem className="menuItem" onClick={(event) => handleListItemClick(event, 2)}>
                      <ListItemText primary="Lékárna 3" />
                      {selectedIndex === 2 ? (
                        <ListItemIcon>
                          <IconCheck color="#fff" />
                        </ListItemIcon>
                      ) : null}
                    </MenuItem>
                  </Menu>
                </div>

                <div className="formLine formLineSingle">
                  <div className="selectButton">
                    <IconDown color="#000" class={pharmacyMenu ? "selected" : ""} />
                    <TextField required id="pharmacyDistrict" label="Okres" defaultValue="Bánovce nad Bebravou" variant="outlined" onClick={handlePharmacyMenuClick} />
                  </div>
                  <Menu
                    id="customized-menu"
                    anchorEl={pharmacyMenu}
                    keepMounted
                    open={Boolean(pharmacyMenu)}
                    onClose={handlePharmacyMenuClose}
                    elevation={0}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <MenuItem className="menuItem" onClick={(event) => handleListItemClick(event, 0)}>
                      <ListItemText primary="Lékárna 1" />
                      {selectedIndex === 0 ? (
                        <ListItemIcon>
                          <IconCheck color="#fff" />
                        </ListItemIcon>
                      ) : null}
                    </MenuItem>
                    <MenuItem className="menuItem" onClick={(event) => handleListItemClick(event, 1)}>
                      <ListItemText primary="Lékárna 2" />
                      {selectedIndex === 1 ? (
                        <ListItemIcon>
                          <IconCheck color="#fff" />
                        </ListItemIcon>
                      ) : null}
                    </MenuItem>
                    <MenuItem className="menuItem" onClick={(event) => handleListItemClick(event, 2)}>
                      <ListItemText primary="Lékárna 3" />
                      {selectedIndex === 2 ? (
                        <ListItemIcon>
                          <IconCheck color="#fff" />
                        </ListItemIcon>
                      ) : null}
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            </Paper>
            <Button text="přidat lékárnu" variant="contained" color="primary" class="goRight" />
          </div>
        </>
      )}
    </div>
  );
};
export default UserOriginal;
