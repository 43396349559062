import { 
  CART_ADD_ITEM, 
  CART_SET_CAMPAIGN, 
  CART_SET_PHARMACY, 
  CART_CHANGE_PRODUCT_QUANTITY,
  CART_UPDATE_PRODUCT_COUNT,
  CART_UPDATE_ITEMS,
  CART_REMOVE_CAMPAIGN
} from "../Types";

export default (state, action) => {

  switch (action.type) {
    case CART_SET_PHARMACY:
      return {
        ...state,
        pharmacy: action.payload
      };

    case CART_SET_CAMPAIGN:
      return {
        ...state,
        campaigns: action.payload
      };

    case CART_REMOVE_CAMPAIGN:
      return {
        ...state,
        campaigns: [
          ...state.campaigns.filter(campaign => campaign.id !== action.payload)
        ]
      }

    case CART_ADD_ITEM:
      const campaigns = state.campaigns

      // find campaign
      const cIndex = (action.payload !== undefined) ? 
        campaigns.findIndex(campaign => campaign?.id === action.payload.campaign.id) // finding index of the campaign
        : -1

      // not exists - add new campaign
      if (cIndex < 0)  {        
        return {
          ...state,
          campaigns: [
            ...campaigns,
            {
              id: (action.payload.campaign === null ? null : action.payload.campaign.id),
              type_id: (action.payload.campaign === null ? null : action.payload.campaign.type_id),
              distributor_code: action.payload.distributorCode,
              name: (action.payload.campaign === null ? 'Without campaign' : action.payload.campaign.name),
              levels: (action.payload.levels === null ? [] : action.payload.campaign.levels),
              items: [{
                product: action.payload.product,
                quantity: action.payload.quantity,
                bonus: action.payload.bonus
              }]
            }
          ]
        };
      }

      // campaign exists - find product
      const pIndex = (action.payload !== undefined) ? 
        campaigns[cIndex].items.findIndex(item => item.product.id === action.payload.product.id) // finding index of the campaign
        : -1

      // not exists - add new product      
      if (pIndex < 0)  {  
        campaigns[cIndex].items = [
          ...campaigns[cIndex].items,
          {
            product: action.payload.product,
            quantity: action.payload.quantity,
            bonus: action.payload.bonus
          }
        ]       
      }
      // update existing product
      else {
        campaigns[cIndex].items[pIndex].quantity +=  action.payload.quantity 
        campaigns[cIndex].items[pIndex].bonus +=  action.payload.bonus 
      }

      // update distributor code
      campaigns[cIndex].distributor_code = action.payload.distributorCode

      return {
        ...state,
        campaigns: [...campaigns]
      };

    case CART_UPDATE_ITEMS:
      const campaignUpdate = state.campaigns
      // find campaign
      const cuIndex = (action.payload.campaignId !== undefined) ? 
        campaignUpdate.findIndex(campaign => campaign.id === action.payload.campaignId) // finding index of the campaign
        : -1
      if (cuIndex > -1)  {  
        campaignUpdate[cuIndex].items = action.payload.items
      }      
      campaignUpdate[cuIndex].distributor_code = action.payload.distributorCode
      return {
        ...state,
        campaigns: [...campaignUpdate]
      }

    case CART_CHANGE_PRODUCT_QUANTITY:
      state.campaigns.find((currentCampaign) => {
        // existing campaign
        if (currentCampaign.id === action.payload.campaignId) {
          // find product
          currentCampaign.items.find((currentItem) => {
            if (currentItem.product.id === action.payload.productId) {
              // update product quantity
              currentItem.quantity =  action.payload.quantity                
            }
            return currentItem.product.id === action.payload.productId
          })
        }
        return currentCampaign.id === action.payload.campaignId        
      })

      return {
        ...state,
        campaigns: [...state.campaigns]
      }
      
    case CART_UPDATE_PRODUCT_COUNT:
      var count = 0;
      var price = 0;
      var discount = 0;
      for (var i = 0, lenc = state.campaigns?.length; i < lenc; i++) {       
        var campaignPrice = 0
        for (var x = 0, lenp = state.campaigns[i].items?.length; x < lenp; x++) {
          count += state.campaigns[i].items[x].quantity
          price += state.campaigns[i].items[x].quantity * state.campaigns[i].items[x].product.price
          campaignPrice += state.campaigns[i].items[x].quantity * state.campaigns[i].items[x].product.price
          if (state.campaigns[i].type_id === 1 || state.campaigns[i].type_id === 6) { // unit || unit free
              discount += state.campaigns[i].items[x].bonus + state.campaigns[i].items[x].product.price
          }          
        }
        if (state.campaigns[i].type_id === 2) { //value
          var currentLevel = null
          // eslint-disable-next-line no-unused-vars
          for (const [index, item] of state.campaigns[i].levels.entries()) {
            if (campaignPrice >= item.level && (currentLevel === null || currentLevel.level < item.level)) {
              currentLevel = item
            }
          }
          discount += campaignPrice * (currentLevel.bonus / 100)
        }        
      }

      return {
        ...state,
        productCount: count,
        totalPrice: price,
        totalDiscount: discount,
      };

    default:
      return state;
  }
};