import React from "react";
import { Link } from "react-router-dom";
import image from "../../../assets/images/Produkt-kampan.png";
import { Typography, TableCell, TableRow, Collapse, Button } from "@material-ui/core";

const OrderItem = (props) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <>
      <TableRow className={`orderItem${props.orderClass ? ` ${props.orderClass}` : ""}`}>
        <TableCell align="left" colspan="2">
          <Typography component="p">39909995739</Typography>
        </TableCell>
        <TableCell align="left" colspan="4">
          <Typography component="p">Lékárna u matky boží, kostelní 41, brušperk</Typography>
        </TableCell>
        <TableCell align="left" colspan="3">
          <Typography component="p" className={`${props.processing ? "processing" : ""}`}>
            Odeslaná
          </Typography>
        </TableCell>
        <TableCell align="left" colspan="2">
          <Typography component="p">19.10.2020</Typography>
        </TableCell>
        <TableCell align="left" colspan="2">
          <Typography component="p">10 755,32 Kč</Typography>
        </TableCell>
        <TableCell align="left" colspan="2">
          <Typography component="p">0,00 Kč</Typography>
        </TableCell>
        <TableCell align="right" colspan="1">
          <Button variant="contained" aria-expanded={expanded} aria-label="show more" onClick={handleExpandClick}>
            detail
          </Button>
        </TableCell>
      </TableRow>
      <TableRow className="collapseItem">
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colspan="16">
          <Collapse in={expanded} timeout="auto" className="collapse" unmountOnExit>
            <div className="orderProduct">
              <img src={image} alt="" />
              <Typography component="p">Název produktu</Typography>
              <Typography component="p">Objednáno 30 kusů</Typography>
              <Typography component="p">Odesláno 30 kusů</Typography>
              <Typography component="p">Distributor: ALLIANCE</Typography>
              <Button variant="contained" component={Link} /* to="/" */>
                zobrazit produkt
              </Button>
            </div>
            <div className="orderProduct">
              <img src={image} alt="" />
              <Typography component="p" className="notSend">
                Název produktu
              </Typography>
              <Typography component="p" className="notSend light">
                Objednáno 30 kusů
              </Typography>
              <Typography component="p" className="notSend">
                Odesláno 30 kusů
              </Typography>
              <Typography component="p">Distributor: ALLIANCE</Typography>
              <Button variant="contained" component={Link} /* to="/" */>
                zobrazit produkt
              </Button>
            </div>
            <div className="orderProduct">
              <img src={image} alt="" />
              <Typography component="p" className="partialySend">
                Název produktu
              </Typography>
              <Typography component="p" className="partialySend">
                Objednáno 30 kusů
              </Typography>
              <Typography component="p" className="partialySend darker">
                Odesláno 30 kusů
              </Typography>
              <Typography component="p">Distributor: ALLIANCE</Typography>
              <Button variant="contained" component={Link} /* to="/" */>
                zobrazit produkt
              </Button>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
export default OrderItem;
