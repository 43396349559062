import React, { } from "react";
import { Typography, Paper, TextField } from "@material-ui/core";
import Button from "../Button/Button";

const PharmacyEdit = () => {
  const handleEditPharmacyClick = () => {
    //
  };

  return (
    <div role="tabpanel" id="simple-tabpanel-0" aria-labelledby="simple-tab-0"  className="tabBodyUser noShadow">
      <Paper p="3">
        <div className="user">
          
            <div className="editPharmacy">
              <div className="editPharmacyHead">
                <Typography component="h6" variant="h6">
                  Základní údaje
                </Typography>
                <Button text="ukončit úpravy" variant="contained" onClick={handleEditPharmacyClick} class="orange" />
              </div>
              <div className="userForm">
                <div className="formLine">
                  <TextField required id="phoneNumber1" label="telefon 1" defaultValue="+420 752 345 424" variant="outlined" />
                  <TextField required id="phoneNumber2" label="telefon 2" defaultValue="+420 752 345 424" variant="outlined" />
                </div>

                <div className="formLine">
                  <TextField required id="phoneNumber3" label="telefon 3" defaultValue="+420 752 345 424" variant="outlined" />
                  <TextField required type="email" id="pharmacyEmail" label="Email" defaultValue="lekarna@gmail.com" variant="outlined" />
                </div>

                <div className="formLine">
                  <div>
                    <Typography component="h6" variant="h6">
                      Kopie registrace lékárny z krajského úřadu
                    </Typography>
                    <input accept="image/*" id="contained-button-file" multiple type="file" style={{ display: "none" }} />
                    <label htmlFor="contained-button-file">
                      <Button variant="contained" color="primary" text="Vyberte soubor" component="span">
                        Upload
                      </Button>
                    </label>
                  </div>
                  <TextField required type="text" id="preferredDistributor" className="preferred" label="Preferovaný distributor" defaultValue="PHOENIX" variant="outlined" />
                </div>
              </div>
            </div>
          </div>
      </Paper>
       
    </div>
  );
};
export default PharmacyEdit;
