import React, { useContext, useEffect } from "react";
import BigCampaign from "../UI/Campaigns/BigCampaign/BigCampaign";
import SmallCampaign from "../UI/Campaigns/SmallCampaign/SmallCampaign";
import Search from "../UI/Search/Search";
import Manufacturers from "../UI/Manufacturers/Manufacturers";
import CampaignContext from "../../context/Campaign/CampaignContext";

const AllCampaigns = () => {
  const campaignContext = useContext(CampaignContext);

  useEffect(
    () => {
      campaignContext.load();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <div className="campaigns">
        <BigCampaign />
        <div className="smallCampaign">
          <SmallCampaign />
          <SmallCampaign />
          <SmallCampaign />
        </div>
      </div>

      <div className="container campaignPage">
        <Search />
        <div className="campaignBlock">
          {campaignContext.loadingRows ?
            <div>Loading...</div>
            :
            campaignContext.rows.map((campaign) => 
              <SmallCampaign key={campaign.id} data={campaign} />
            ) 
          }
        </div>

        <div className="separate">
          <Manufacturers />
        </div>
      </div>
    </>
  );
};
export default AllCampaigns;
