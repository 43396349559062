import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import IconPlus from "../Icons/IconPlus";
import IconMinus from "../Icons/IconMinus";

const ProductQuantity = ({quantity, setQuantity, rabatColor = false}) => {
  const incrementQuantity = () => {
    setQuantity(quantity + 1);
  };
  const decrementQuantity = () => {
    if (quantity === 1) {
      return;
    }
    setQuantity(quantity - 1);
  };

  return (
    <div className={`productQuantity${rabatColor === true ? " rabatButton" : ""}`}>
      <Button onClick={decrementQuantity}>
        <IconMinus color="white" />
      </Button>
      <span className="quantity">{quantity} KS</span>
      <Button onClick={incrementQuantity} aria-label="increment">
        <IconPlus color="white" />
      </Button>      
    </div>
  );
};

ProductQuantity.propTypes = {
  quantity: PropTypes.number.isRequired,
  setQuantity: PropTypes.func.isRequired,
  rabatColor: PropTypes.bool,
}

export default ProductQuantity;
