import React, { Fragment, useContext, useEffect, useState } from "react";
import Axios from "axios";
import AuthContext from "../../../context/Auth/AuthContext";
import CartContext from "../../../context/Cart/CartContext";
import PharmacyItem from "./PharmacyItem";

const PharmacyList = () => {
  const authContext = useContext(AuthContext);
  const cartContext = useContext(CartContext);

  const [loaded, setLoaded] = useState(false)
  const [pharmacies, setPharmacies] = useState([])

  useEffect(() => {
    if (authContext.isAuthenticated) {
      loadPharmacies()
    }        
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadPharmacies = async ()  => {
    Axios.get("/user/pharmacy")
    .then((response) => {
      setPharmacies(response.data.data)      
    })
    .then(() => setLoaded(true))
    .catch(err => console.log("Api call err: ", err))  
  }

  if (loaded) {
    return (
      <div role="tabpanel" id="simple-tabpanel-0" aria-labelledby="simple-tab-0"  className="tabBodyUser noShadow">
        {loaded ?
        <Fragment>
          {pharmacies.map(item =>
            <PharmacyItem 
              key={item.info.id} 
              data={item} 
              selected={item.info.id === cartContext.pharmacy?.info.id
              }
            />
          )}              
        </Fragment>
        : <div>Loading user information...</div>
        }   
      </div>
    );
  }

  return ('');
  
};
export default PharmacyList;
