import React, { useReducer } from "react";
import axios from "axios";

import { BRAND_ITEMS } from "../Types";

import BrandContext from "./BrandContext";
import BrandReducer from "./BrandReducer";

const BrandState = (props) => {
  const initialState = {
    items: null,
  };

  const [state, dispatch] = useReducer(BrandReducer, initialState);

  const setBrandsItems = async () => {
    try {
      const res = await axios.get("/brand");
      // console.log("res", res.data.data);
      dispatch({
        type: BRAND_ITEMS,
        payload: res.data.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  /* useEffect(() => {
    setBrandsItems();
    console.log("brand effect", state);
  }, []);
  console.log("brand", state); */

  return (
    <BrandContext.Provider
      value={{
        items: state.items,
        setBrandsItems,
      }}
    >
      {props.children}
    </BrandContext.Provider>
  );
};
export default BrandState;
