import React, { useState, useContext, useEffect } from "react";
import { FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";

import BrandContext from "../../../context/Brand/BrandContext";

const FilterManufacturers = () => {
  const brandContext = useContext(BrandContext);
  const [state, setState] = useState({ checkedA: false });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    brandContext.setBrandsItems();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormGroup aria-label="position" className="filter filterManufacturer">
      {brandContext.items
        ? brandContext.items.map((item) => (
            <FormControlLabel key={item.id} control={<Checkbox checked={state.checkedA} onChange={handleChange} name={item.name} color="primary" />} label={item.name} />
          ))
        : null}
    </FormGroup>
  );
};
export default FilterManufacturers;
