import React from "react";
import Search from "../UI/Search/Search";
import { Typography } from "@material-ui/core";
import AutoComplete from "../UI/AutoComplete/AutoComplete";
import Datepicker from "../UI/Datepicker/Datepicker";
import Button from "../UI/Button/Button";
import { default as OrderItem } from "../UI/Order/Order";
import Pagination from "../UI/Pagination/Pagination";

const Orders = () => {
  return (
    <div className="container">
      <Search />
      <Typography component="h5" variant="h5">
        Objednávky
      </Typography>
      <div className="head">
        <AutoComplete />
        <AutoComplete />
        <Datepicker label="from" />
        <Datepicker label="to" />
        <Button color="primary" variant="contained" text="Vyhledat"></Button>
      </div>

      <div className="content">
        <Typography component="h6" variant="h6">
          Aktivní
        </Typography>
        <div className="objednavky">
          <OrderItem />
        </div>
        <Pagination />
      </div>
    </div>
  );
};
export default Orders;
