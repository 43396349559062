import React, { useReducer, useEffect } from "react";
import CampaignCartContext from "./CampaignCartContext";
import CampaignCartReducer from "./CampaignCartReducer";


const CampaignCartState = (props) => {
  const initialState = {
    loaded: false,
    campaign: null,
    items: [],
    totalPrice: 0,
    productCount: 0,
    topProduct: null,
    discount: 0,
    availableBonus: 0,
    currentLevel: null,
    nextLevel: null,
    maxLevel: null,
    distributorCode: null
  };

  const [state, dispatch] = useReducer(CampaignCartReducer, initialState);

  useEffect(() => {
    if (state.campaign !== null) {
      updateDiscount()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.campaign, state.totalPrice, state.loaded])
  useEffect(() => {
      updateCounts()    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.items, state.currentLevel])

  const load = ( campaign, cart = [] ) => {
    let maxLevel = null
    // eslint-disable-next-line no-unused-vars
    for (const [index, item] of campaign.levels.entries()) {
      // update max level
      if (maxLevel === null || parseInt(maxLevel.level) < parseInt(item.level)) {
        maxLevel = item
      }   
    }
    dispatch({ type: 'load', payload: { campaign, maxLevel, items: cart }})    
  }

  const addItem = (product) => {
    dispatch({ type: 'increment', payload: product })
  }
  const removeItem = (product) => {
    dispatch({ type: 'decrement', payload: product })
  }
  const addBonusItem = (product) => {
    dispatch({ type: 'increment_bonus', payload: product })
  }
  const removeBonusItem = (product) => {
    dispatch({ type: 'decrement_bonus', payload: product })
  }

  const setDistributorCode = (code) => {
    dispatch({ type: 'distributor_code', payload: code })
  }

  const updateCounts = () =>  {
    let price = 0
    let quantity = 0
    let availableBonus = 0
    // eslint-disable-next-line no-unused-vars
    for (const [index, item] of state.items.entries()) {      
      price += (item.product.price * item.quantity)
      quantity += item.quantity
    }
    // count available bonus    
    if (state.campaign && state.campaign.type_id === 6 && state.currentLevel !== null) {
      availableBonus =  state.currentLevel.bonus - state.items.reduce((s, v) => s = s + v.bonus, 0)
    }    
    dispatch({ type: 'update_counts', payload: { price, quantity, availableBonus }})    
  }

  const updateDiscount = () => {

    let currentLevel = null
    let nextLevel = null

    switch (state.campaign.type_id) {      

      case 1: // unit
        
        let topProduct = null
        let productBonus = []
        // check all product status
        // eslint-disable-next-line no-unused-vars
        for (const [index, item] of state.items.entries()) {
          // check levels
          let bonusLevel = null
          // eslint-disable-next-line no-unused-vars
          for (const [index, level] of state.campaign.levels.entries()) {
            // update current level
            if (item.quantity >= level.level && (bonusLevel === null || bonusLevel.level < level.level)) {
              bonusLevel = level
            }            
          }
          if (bonusLevel !== null) {
            productBonus = [...productBonus, { productId: item.product.id, bonus: bonusLevel.bonus }]
          }
          // find top product      
          if (topProduct === null || topProduct.quantity < item.quantity) {
            topProduct = item            
          }          
        }

        // get levels for top product        
        // eslint-disable-next-line no-unused-vars
        if (topProduct !== null) {
          // eslint-disable-next-line no-unused-vars
          for (const [index, level] of state.campaign.levels.entries()) {
            // update current level
            if (topProduct.quantity >= level.level && (currentLevel === null || currentLevel.level < level.level)) {
              currentLevel = level
            }
            // update next level
            if (topProduct.quantity < level.level && (nextLevel === null || nextLevel.level > level.level)) {
              nextLevel = level
            }
          }
         }
        
        dispatch({type: 'update_level', payload: { currentLevel, nextLevel, topProduct, productBonus }})
        break;

      case 2: // value
        // eslint-disable-next-line no-unused-vars
        for (const [index, item] of state.campaign.levels.entries()) {
          // update current level
          if (state.totalPrice >= item.level && (currentLevel === null || currentLevel.level < item.level)) {
            currentLevel = item
          }
          // update next level
          if (state.totalPrice < item.level && (nextLevel === null || nextLevel.level > item.level)) {
            nextLevel = item
          }
        }
        dispatch({type: 'update_level', payload: { currentLevel, nextLevel }})
        break;

      case 6: // unitfree
        // eslint-disable-next-line no-unused-vars
        for (const [index, item] of state.campaign.levels.entries()) {
          // update current level
          if (state.productCount >= item.level && (currentLevel === null || currentLevel.level < item.level)) {
            currentLevel = item
          }
          // update next level
          if (state.productCount  < item.level && (nextLevel === null || nextLevel.level > item.level)) {
            nextLevel = item
          }
        }

        dispatch({type: 'update_level', payload: { currentLevel, nextLevel }})
        break;

      default:
        break;
    }
    
  }
  
  return (
    <CampaignCartContext.Provider
      value={{        
        loaded: state.loaded,
        campaign: state.campaign,
        items: state.items,
        totalPrice: state.totalPrice,
        productCount: state.productCount,
        topProduct: state.topProduct,
        discount: state.discount,
        availableBonus: state.availableBonus,
        currentLevel: state.currentLevel,
        nextLevel: state.nextLevel,
        maxLevel: state.maxLevel,
        distributorCode: state.distributorCode,

        load,
        addItem,
        removeItem,
        addBonusItem,
        removeBonusItem,
        setDistributorCode
      }}
    >
      {props.children}
    </CampaignCartContext.Provider>
  );
};

export default CampaignCartState;
