import React, {  useContext, useEffect } from "react";
import Search from "../UI/Search/Search";
import { Typography } from "@material-ui/core";
import CampaignContext from "../../context/Campaign/CampaignContext";
import moment from 'moment'
import CampaignType from "../UI/Campaigns/CampaignType";
import CampaignUnit from "../UI/Campaigns/Type/Unit/Index";
import CampaignValue from "../UI/Campaigns/Type/Value/Index";
import CampaignUnitFree from "../UI/Campaigns/Type/UnitFree/Index";
import CampaignCartState from "../../context/CampaignCart/CampaignCartState";

const Campaigns = ({ match }) => {
  const campaignContext = useContext(CampaignContext);  

  useEffect(() => {
    campaignContext.detail(match.params.id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])  

  const currentCampaign = ( typeId ) => {
    switch(typeId) {
      case 1: // unit
        return (
          <CampaignUnit campaign={campaignContext.current} />
        )
      case 2: // value
        return (
          <CampaignValue campaign={campaignContext.current} />
        )     
      case 6: // unit free
        return (
          <CampaignUnitFree campaign={campaignContext.current} />
        )     
      default:
            return (<div>not implemented id {typeId}</div>)
    }
  }

  return (
    <div className="container">
      <Search />     
        <div className="kampanePage">
          <div className="left">
            <Typography component="h4" variant="h4" gutterBottom>
              {campaignContext.current?.name} (Type={CampaignType(campaignContext.current?.type_id)})
            </Typography>
            <Typography component="p" className="semiBold" gutterBottom>
              Platné do {moment(campaignContext.current?.valid_to).format('DD.MM.YYYY') }
            </Typography>
            <Typography component="p" gutterBottom>
              Si sasd fine causa, nollem me tamen laudandis maioribus meis corrupisti nec segniorem ad respondendum reddidisti quorum facta quem modo ista sis aequitate, quam interrogare
              aut in animis nostris inesse notionem, ut de quo quaerimus, non recusandae itaque aiunt hanc quasi architecto beatae vitae dicta sunt. Primum igitur, quid aut dolores
              suscipiantur maiorum dolorum fuga et accurate disserendum et benivole collegisti, nec me tamen laudandis maioribus meis corrupisti nec me tamen laudandis maioribus meis
              corrupisti nec me ab illo inventore veritatis et caritatem, quae sine causa, nollem me tamen laudandis maioribus.
            </Typography>            
          </div>
          <CampaignCartState>
            {campaignContext.current && currentCampaign(campaignContext.current.type_id)}
          </CampaignCartState>
          
        </div>        
    </div>
  );
};
export default Campaigns;
