import React, {  useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import Axios from "axios";

const PharmacyAddCity = ({ value, setValue, district }) => {

  const [items, setItems] = useState([]);

  useEffect(() => {
    loadItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [district])
  
  const loadItems = async() => {
    Axios.get("/pharmacy/city", { params: { district }})
    .then((response) => {
      setItems(response.data.data)      
    })
    .catch(err => console.log("Api call err: ", err))  
  }

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl className="selectButton">
        <InputLabel id="pharmacy-city-select-label">Mesto</InputLabel>
        <Select
          labelId="pharmacy-city-select-label"
          id="pharmacy-city-select"
          value={value}
          onChange={handleChange}
          variant="outlined"
        >
          {items.map((item) => <MenuItem key={item.name} value={item.name}>{item.name}</MenuItem>)}
        </Select>
      </FormControl>
  )
}
PharmacyAddCity.prototype = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  district: PropTypes.string.isRequired,
}

export default PharmacyAddCity;