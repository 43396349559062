import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from "@material-ui/core";
import Axios from "axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import AuthContext from "../../../context/Auth/AuthContext";
import CartContext from "../../../context/Cart/CartContext";
import IconCheck from "../Icons/IconCheck";

const PharmacyButton = () => {
  const authContext = useContext(AuthContext);
  const cartContext = useContext(CartContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [loaded, setLoaded] = useState(false);
  const [pharmacies, setPharmacies] = useState([]);

  useEffect(() => {
    if (authContext.isAuthenticated) {
      loadPharmacies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadPharmacies = async () => {
    Axios.get("/user/pharmacy")
      .then((response) => {
        setPharmacies(response.data.data);
        if (response.data.data[0]) {
          cartContext.setPharmacy(response.data.data[0]);
        } else {
          console.warn("No default pharmacy!");
        }
      })
      .then(() => {
        setLoaded(true);
      })
      .catch((err) => console.log("Api call err: ", err));
  };

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleListItemClick = (event, pharmacy) => {
    cartContext.setPharmacy(pharmacy);
    handleClose();
  };

  return (
    <Fragment>
      <Button
        variant="outlined"
        color="primary"
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Objednáváte pro lékárnu: {cartContext.pharmacy === null ? "------" : cartContext.pharmacy.info.name}
      </Button>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {loaded &&
          pharmacies.map((item) => (
            <MenuItem key={item.info.id} className="menuItem" onClick={(event) => handleListItemClick(event, item)}>
              <ListItemText primary={`${item.info.name}, ${item.info.street}, ${item.info.city}, ${item.info.postcode}`} />
              {item.info.id === cartContext.pharmacy?.info.id && (
                <ListItemIcon>
                  <IconCheck color="#fff" />
                </ListItemIcon>
              )}
            </MenuItem>
          ))}
      </Menu>
    </Fragment>
  );
};
export default PharmacyButton;
