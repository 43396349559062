import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Typography, Paper, TextField } from "@material-ui/core";
import Button from "../Button/Button";
import { USER_ACTION_PHARMACY_LIST } from "../../Pages/User";
import PharmacyAddDistrict from "./PharmacyAddDistrict";
import PharmacyAddCity from "./PharmacyAddCity";
import PharmacyAddPharmacy from "./PharmacyAddPharmacy";
import PharmacyAddDistributor from "./PharmacyAddDistributor";
import Axios from "axios";

const PharmacyAdd = ({ setPageIndex }) => {

  const [district, setDistrict] = useState(null);
  const [city, setCity] = useState(null);
  const [pharmacy, setPharmacy] = useState(null);
  const [record, setRecord] = useState({
    pharmacy_id: null,
    phone: "",
    phone_2: "",
    phone_3: "",
    email: "",
    distributor: null
  });
  const [saved, setSaved] = useState(false);  
  
  useEffect(() => {
    //setCity(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [district])
  useEffect(() => {
    //setPharmacy(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city])

  const onChange = (e) => {
    setRecord({ ...record, [e.target.name]: e.target.value })
    setSaved(false)
  };
  const setDistributor = id => { setRecord({ ...record, distributor: id })}

  const addPharmacy = (e) => {
    Axios.post(`/user/pharmacy`, { ...record, pharmacy_id: pharmacy })
    .then((response) => {
      console.log(response)
      setSaved(true)  
      setPageIndex(USER_ACTION_PHARMACY_LIST)    
    })
    .catch(err => console.log("Api call err: ", err))  
  };
  
  return (
    <div className="container">
      <div className="newPharmacyPage">
        {saved}
        <div className="newPharmacyHead">
          <Typography component="h5" variant="h5">
            Nová lékárna
          </Typography>
          <Button text="zrušit" variant="contained" className="orange" onClick={() =>  setPageIndex(USER_ACTION_PHARMACY_LIST) } />
        </div>
        <Paper className="newPharmacy">
          <div className="newPharmacyForm">
            <div className="formLine">
              <PharmacyAddDistrict value={district} setValue={setDistrict} />
              {district !== null && 
                <PharmacyAddCity value={city} setValue={setCity} district={district} />
              }
            </div>

            <div className="formLine formLineSingle">
              {city !== null && 
                <PharmacyAddPharmacy value={pharmacy} setValue={setPharmacy} district={district} city={city} />
              }
            </div>
          </div>

          {pharmacy !== null &&            
              <div className="editPharmacy">
                <div className="editPharmacyHead">
                  <Typography component="h6" variant="h6">
                    Základní údaje
                  </Typography>
                </div>
                <div className="userForm">
                  <div className="formLine">
                    <TextField required id="phoneNumber1" label="telefon 1" variant="outlined" name="phone" onChange={onChange} />
                    <TextField required id="phoneNumber2" label="telefon 2" variant="outlined" name="phone_2" onChange={onChange} />
                  </div>

                  <div className="formLine">
                    <TextField required id="phoneNumber3" label="telefon 3" variant="outlined" name="phone_3" onChange={onChange} />
                    <TextField required type="email" id="pharmacyEmail" label="Email" variant="outlined" name="email" onChange={onChange} />
                  </div>

                  <div className="formLine formLineSingle">  
                    <PharmacyAddDistributor value={record.distributor} setValue={setDistributor} pharmacy={pharmacy} />                 
                  </div>
                </div>
              </div>            
          }       
        </Paper>
        {(record.phone !== "" && record.email !== "" && record.distributor !== null) ?
          <Button text="přidat lékárnu" variant="contained" color="primary" class="goRight" onClick={() => addPharmacy()} />
          :
          <div class="goRight">Vyplňte prosím povinné údaje</div>
        }
        
      </div>
    </div>
  );
};

PharmacyAdd.prototype = {
  setPageIndex: PropTypes.func.isRequired,
}

export default PharmacyAdd;
