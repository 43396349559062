import React from "react";
import PropTypes from 'prop-types'
import { Typography } from "@material-ui/core";
import Button from "../Button/Button";

const GrandTotal = ({ checkout, totalPrice, totalDiscount }) => {
  return (
    <div className="grandTotal">
      <Typography component="h6" variant="h5">
        Grand total
      </Typography>
      <div className="buttonLine">
        <Button text={`Zboží za ${totalPrice.toFixed(2)} \u20ac`} variant="outlined" color="primary" />
        <Button text={`Aktuální sleva ${(totalDiscount / totalPrice * 100).toFixed(0)} %`} variant="outlined" color="primary" />
        <Button text={`Ušetříte ${totalDiscount.toFixed(2)} \u20ac`} variant="outlined" color="primary" />
        <Button text="Závazně objednat" variant="contained" color="primary" onClick={checkout} />
      </div>
    </div>
  );
};

GrandTotal.propTypes = {
  checkout: PropTypes.func.isRequired,
  totalPrice: PropTypes.number.isRequired,
  totalDiscount: PropTypes.number.isRequired,
}
export default GrandTotal;
