import React, { useContext, useEffect } from "react";
import { Typography } from "@material-ui/core";
import Product from "../Products/Product";

import AuthContext from "../../../context/Auth/AuthContext";
import ProductContext from "../../../context/Product/ProductContext";

const OtherProducts = () => {
  const authContext = useContext(AuthContext);
  const productContext = useContext(ProductContext);

  useEffect(() => {
    if (authContext.isAuthenticated) {
      productContext.search();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const product = productContext.rows.slice(0, 5);

  console.log("product", product);

  return (
    <div className="otherProducts">
      <Typography component="h5" variant="h5" className="h5">
        Ostatní produkty:
      </Typography>
      <div className="line">{productContext.loadingRows ? <h3>DODELAT LOADER!!!!!</h3> : product.map((item) => <Product key={item.id} data={item} badge="campaign" />)}</div>
    </div>
  );
};
export default OtherProducts;
