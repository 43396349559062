
export default (state, action) => {

  switch (action.type) {

    case 'load':
      return {
        ...state,
        campaign: action.payload.campaign,
        maxLevel: action.payload.maxLevel,
        items: action.payload.items,
        loaded: true
      }    
        
    case 'increment':      
      const incIndex = (action.payload !== undefined) ? 
        state.items.findIndex(item => item.product.id === action.payload.id) // finding index of the item
        : -1
      if (incIndex < 0)  {
        return {
          ...state,
          items: [ ...state.items, { product: action.payload, quantity: 1, bonus: 0 } ]
        } 
      } else {
        const items = state.items;
        items[incIndex].quantity++
        return { ...state, items: [ ...items ] } 
      }
  
    case 'decrement':
      const decIndex = (action.payload !== undefined) ? 
        state.items.findIndex(item => item.product.id === action.payload.id) // finding index of the item
        : -1  
      if (decIndex >= 0)  {
        if ((state.items[decIndex].quantity - 1) < 1 && state.items[decIndex].bonus < 1) {  // check product from cart
          return {  // remove item from an array
            ...state,
            items: state.items.filter((item) => item.product.id !== action.payload.id)
          }
        } else {
          const items = state.items
          items[decIndex].quantity = items[decIndex].quantity > 1 ? items[decIndex].quantity - 1 : 0        
          return { ...state, items: [...state.items] } 
        }        
      }
      return { ...state }

    case 'increment_bonus':
      if (state.availableBonus > 0) {
        const ibIndex = (action.payload !== undefined) ? 
          state.items.findIndex(item => item.product.id === action.payload.id) // finding index of the item
          : -1
        if (ibIndex < 0)  {
          return {
            ...state,
            items: [ ...state.items, { product: action.payload, quantity: 0, bonus: 1 } ]
          } 
        } else {
          const items = state.items
          items[ibIndex].bonus++          
          return { ...state, items: [...items] } 
        }        
      }
      return { ...state } 
      
  
    case 'decrement_bonus':
      const dbIndex = (action.payload !== undefined) ? 
        state.items.findIndex(item => item.product.id === action.payload.id) // finding index of the item
        : -1  
      if (dbIndex >= 0)  {
        if ((state.items[dbIndex].bonus - 1) < 1 && state.items[dbIndex].quantity < 1) {  // check product from cart
          return {  // remove item from an array
            ...state,
            items: state.items.filter((item) => item.product.id !== action.payload.id)
          }
        } else {
          const items = state.items
          items[dbIndex].bonus = items[dbIndex].bonus > 1 ? items[dbIndex].bonus - 1 : 0        
          return { ...state, items: [...state.items] } 
        }        
      } 
      return { ...state } 

    case 'update_counts':
      return {
        ...state,
        totalPrice: action.payload.price,
        productCount: action.payload.quantity,
        availableBonus: action.payload.availableBonus
      }

    case 'update_level':
      return {
        ...state,
        currentLevel: action.payload.currentLevel,
        nextLevel: action.payload.nextLevel,
        topProduct: action.payload.topProduct ? action.payload.topProduct : null,
        availableBonus: action.payload.availableBonus ? action.payload.availableBonus : 0
      }

    case 'distributor_code':
      return {
        ...state,
        distributorCode: action.payload
      }

    default:
      return state;
  }
};