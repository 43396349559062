import React, { useReducer, useEffect } from "react";
import CartContext from "./CartContext";
import CartReducer from "./CartReducer";
import { 
  CART_SET_PHARMACY, 
  CART_ADD_ITEM, 
  CART_UPDATE_PRODUCT_COUNT, 
  CART_SET_CAMPAIGN, 
  CART_CHANGE_PRODUCT_QUANTITY,
  CART_UPDATE_ITEMS,
  CART_REMOVE_CAMPAIGN,
  CHECKOUT,
  CHECKOUT_ERROR
} from "../Types";
import Axios from "axios";

const CartState = (props) => {
  const initialState = {    
    pharmacy: localStorage.getItem("pharmacy") ? JSON.parse(localStorage.getItem("pharmacy")) : null,
    campaigns: localStorage.getItem("campaigns") ? JSON.parse(localStorage.getItem("campaigns")) : [],
    productCount: 0,
    totalPrice: 0,
    totalDiscount: 0,
    error: null,
  };

  const [state, dispatch] = useReducer(CartReducer, initialState);

  // load data from local storage
  useEffect(() => {
    if (localStorage.getItem("campaigns") && JSON.parse(localStorage.getItem("campaigns")).length > 0) {
      dispatch({
        type: CART_SET_CAMPAIGN,
        payload: JSON.parse(localStorage.getItem("campaigns"))
      })
    }    
  }, [])

  // persist cart
  useEffect(() => {
    localStorage.setItem("pharmacy", JSON.stringify(state.pharmacy));
    localStorage.setItem("campaigns", JSON.stringify(state.campaigns));
  }, [state.pharmacy, state.campaigns]);

  // update counts
  useEffect(() => {
    dispatch({
      type: CART_UPDATE_PRODUCT_COUNT,
      // payload: { campaigns: campaignContext.rows }
    });
  }, [state.campaigns]);

  const setPharmacy = (pharmacy) => {
    dispatch({
      type: CART_SET_PHARMACY,
      payload: pharmacy,
    });
  };

  const addItem = ({ product, quantity, campaign = null }) => {
    dispatch({
      type: CART_ADD_ITEM,
      payload: { product, quantity, campaign },
    });
  };
  const setQuantity = ({ productId, quantity, campaignId = null}) => {
    dispatch({
      type: CART_CHANGE_PRODUCT_QUANTITY,
      payload: { productId, quantity, campaignId }
    })
  }

  const addCampaign = ({ items, campaign, distributorCode }) => {
    // eslint-disable-next-line no-unused-vars
    for (const [index, item] of items.entries()) {
      dispatch({
        type: CART_ADD_ITEM,
        payload: { 
          product: item.product, 
          quantity: item.quantity, 
          bonus: item.bonus, 
          campaign,
          distributorCode: distributorCode
        },
      });
    }
  }

  const updateCampaign = ({ campaignId, items, distributorCode }) => {
    // eslint-disable-next-line no-unused-vars
    dispatch({
      type: CART_UPDATE_ITEMS,
      payload: { 
        items, 
        campaignId,
        distributorCode: distributorCode
      },
    });
  }

  const removeCampaign = (id) => {
    dispatch({
      type: CART_REMOVE_CAMPAIGN,
      payload: id
    })
  }

  const checkout = async() => {

    // send complete order to an api
    try {
      const res = await Axios.post(`/checkout`, {
        pharmacy_id: state.pharmacy.id,
        campaigns: state.campaigns,
      });

      console.log(res)

      dispatch({
        type: CHECKOUT,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: CHECKOUT_ERROR,
        payload: err,
      });
    }
  }

  return (
    <CartContext.Provider
      value={{
        pharmacy: state.pharmacy,
        campaigns: state.campaigns,
        productCount: state.productCount,
        totalPrice: state.totalPrice,
        totalDiscount: state.totalDiscount,
        error: state.error,

        setPharmacy,
        addItem,        
        setQuantity,
        addCampaign,
        updateCampaign,
        removeCampaign,
        checkout
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
};

export default CartState;
