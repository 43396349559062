import React, { useContext, useEffect, useState } from "react";
import { Typography, Paper, TextField } from "@material-ui/core";
import Button from "../Button/Button";
import AuthContext from "../../../context/Auth/AuthContext";
import Axios from "axios";

const UserAccount = () => {
  const authContext = useContext(AuthContext);

  const [loaded, setLoaded] = useState(false)
  const [user, setUser] = useState({
    'first_name' : '',
    'last_name' : '',
    'email' : '',
    'phone' : '',
  })
  const onChange = (e) => setUser({ ...user, [e.target.name]: e.target.value });

  useEffect(() => {
    if (authContext.isAuthenticated) {
      loadUser()
    }        
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadUser = async ()  => {
    Axios.get("/user")
    .then((response) => {
      setUser(response.data.data)      
    })
    .then(() => setLoaded(true))
    .catch(err => console.log("Api call err: ", err))  
  }
  const saveUser = () => {
    // TODO: add validation  
    setLoaded(false)
    Axios.post("/user", user)
    .then((response) => {
      setUser(response.data.data)      
    })
    .then(() => setLoaded(true))
    .catch(err => console.log("Api call err: ", err))  
  };

  return (
    <div role="tabpanel" id="simple-tabpanel-0" aria-labelledby="simple-tab-0"  className="tabBodyUser noShadow">
      <Paper p="3">
        <div className="user">
            <Typography component="h6" variant="h6">
              Základní údaje
            </Typography>

            <form onSubmit={saveUser}>
            <div className="userForm">
              <div className="formLine">
                <TextField required id="name" label="Jméno" name="first_name" value={user.first_name} onChange={onChange} variant="outlined" />
                <TextField required id="surname" label="Přijmení" name="last_name" value={user.last_name} onChange={onChange} variant="outlined" />
              </div>

              <div className="formLine">
                <TextField required type="email" id="email" label="Email" name="email" value={user.email} onChange={onChange} variant="outlined" />
                <TextField required id="phone" label="Telefon" name="phone" value={user.phone} onChange={onChange} variant="outlined" />
              </div>

              {loaded ?
                <Button text="Uložit změny" onClick={() => {saveUser()}} type="submit" class="orange icon iconSeparate" iconColor="white" icon="edit" variant="contained" />
                : <div>Loading user information...</div>
              }            
            </div>
            </form>

            <Typography component="h6" variant="h6">
              Změna hesla
            </Typography>

            <div className="userForm">
              <div className="passwordLine">
                <TextField required type="password" id="oldPassword" label="původní heslo" defaultValue="" variant="outlined" />
                <TextField required type="password" id="newPassword" label="nové heslo" defaultValue="" variant="outlined" />
                <TextField required type="password" id="recapPassword" label="potvrzení nového hesla" defaultValue="" variant="outlined" />
              </div>
              <Button text="změnit heslo" class="orange icon iconSeparate" iconColor="white" icon="trash" variant="contained" />
            </div>
          </div>
      </Paper>
    </div>
  );
};
export default UserAccount;
