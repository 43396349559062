import React, { useContext, useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import PropTypes from 'prop-types'
import { Button, Typography } from '@material-ui/core'
import moment from 'moment'
import CampaignMeter from './CampaignMeter'
import ProductList from './ProductList'
import CartContext from '../../../../../context/Cart/CartContext'
import CampaignCartContext from '../../../../../context/CampaignCart/CampaignCartContext'
import SupplierSelect from '../../../SupplierSelect/SupplierSelect'

const Cart = ({ campaign, cart }) => {
  const ccContext = useContext(CampaignCartContext)
  const cartContext = useContext(CartContext)
  const [showSupplier, setShowSupplier] = useState(false)
  
  useEffect(() => {
    ccContext.load(campaign, cart.items)
    if (cartContext.pharmacy.distributor_code.length > 0) {
      ccContext.setDistributorCode(cart.distributor_code)
      setShowSupplier(true)      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (ccContext.loaded) {
      cartContext.updateCampaign({
        campaignId: campaign.id,
        items: ccContext.items,
        distributorCode: ccContext.distributorCode,   
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ccContext.items, ccContext.distributorCode])

  return (
    <div className="content">

      <div className="sale">
        <div className="line">
          <div>
            <Typography component="h6" variant="h6">
              Zboží z kampaně {campaign.name}
            </Typography>
            <Typography component="p">Platné do {moment(campaign.valid_to).format('DD.MM.YYYY') }</Typography>
          </div>
        </div>
      </div>

      {ccContext.loaded && <CampaignMeter context={ccContext} cart={cart} />}

      <div className="informationLine">
        {showSupplier && 
          <SupplierSelect 
            distributorCode={ccContext.distributorCode} 
            setDistributorCode={ccContext.setDistributorCode}
          />
        }
        {ccContext.nextLevel !== null ?
          <Button variant="outlined">Do další slevy zbývá {(ccContext.nextLevel.level - ccContext.topProduct?.quantity)} ks
          </Button> :
          ccContext.topProduct && <Button variant="outlined">Dosáhli jste maximální slevy</Button>
        }             
      </div>

      <div className="kosikProducts">
        <div className="kosikProductsBody">
          <ProductList 
            products={cart.items}
            context={ccContext}
          />
          <div className="line">
            <Button variant="contained" onClick={() => cartContext.removeCampaign(campaign.id)}>Odebrat kampaň z košíku</Button>
            <Link to={`/campaigns/${campaign.id}`}>
              <Button variant="contained" color="primary">
                Otevřít znovu kampaň
              </Button>
            </Link>            
          </div>
        </div>
      </div>
    </div>
  )
}

Cart.propTypes = {  
  campaign: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
}

export default Cart
