// Auth
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

// Products
export const PRODUCT_SEARCH = "PRODUCT_SEARCH";
export const PRODUCT_DETAIL = "PRODUCT_DETAIL";
export const PRODUCT_ERROR = "PRODUCT_ERROR";
export const PRODUCT_ROWS_LOADING = "PRODUCT_ROWS_LOADING";
export const PRODUCT_DETAIL_LOADING = "PRODUCT_DETAIL_LOADING";
export const PRODUCT_LIMIT = "PRODUCT_LIMIT";
export const PRODUCT_PAGE = "PRODUCT_PAGE";
export const PRODUCT_FILTER = "PRODUCT_FILTER";
export const PRODUCT_SORT = "PRODUCT_SORT";

// Campaigns
export const CAMPAIGN_LIST = "CAMPAIGN_LIST";
export const CAMPAIGN_DETAIL = "CAMPAIGN_DETAIL";
export const CAMPAIGN_ERROR = "CAMPAIGN_ERROR";
export const CAMPAIGN_ROWS_LOADING = "CAMPAIGN_ROWS_LOADING";
export const CAMPAIGN_DETAIL_LOADING = "CAMPAIGN_DETAIL_LOADING";
export const CAMPAIGN_LIMIT = "CAMPAIGN_LIMIT";
export const CAMPAIGN_PAGE = "CAMPAIGN_PAGE";
export const CAMPAIGN_FILTER = "CAMPAIGN_FILTER";
export const CAMPAIGN_SORT = "CAMPAIGN_SORT";

// Cart
export const CART_SET_PHARMACY = "CART_SET_PHARMACY";
export const CART_SET_CAMPAIGN = "CART_SET_CAMPAIGN";
export const CART_REMOVE_CAMPAIGN = "CART_REMOVE_CAMPAIGN";
export const CART_ADD_ITEM = "CART_ADD_ITEM";
export const CART_UPDATE_ITEMS = "CART_UPDATE_ITEMS";
export const CART_CHANGE_PRODUCT_QUANTITY = "CART_CHANGE_PRODUCT_QUANTITY";
export const CART_UPDATE_PRODUCT_COUNT = "CART_UPDATE_PRODUCT_COUNT";
export const CART_SET_DISTRIBUTOR_CODE = "CART_SET_DISTRIBUTOR_CODE";
export const CHECKOUT = "CHECKOUT";
export const CHECKOUT_ERROR = "CHECKOUT_ERROR";


// Brand
export const BRAND_ITEMS = "BRAND_ITEMS";

