import React from "react";
import { Typography } from "@material-ui/core";

import BigCampaign from "../UI/Campaigns/BigCampaign/BigCampaign";
import SmallCampaign from "../UI/Campaigns/SmallCampaign/SmallCampaign";
import Search from "../UI/Search/Search";
import Manufacturer from "../UI/Manufacturers/Manufacturer";
import Pagination from "../UI/Pagination/Pagination";

const AllManufacturers = () => {
  return (
    <>
      <div className="campaigns">
        <BigCampaign />
        <div className="smallCampaign">
          <SmallCampaign />
          <SmallCampaign />
          <SmallCampaign />
        </div>
      </div>

      <div className="container manufacturerPage">
        <Search />
        <Typography component="h5" className="headline">
          Výrobci
        </Typography>
        <div className="manufacturerBlock">
          <Manufacturer />
          <Manufacturer />
          <Manufacturer />
          <Manufacturer />
          <Manufacturer />
          <Manufacturer />
          <Manufacturer />
          <Manufacturer />
          <Manufacturer />
          <Manufacturer />
          <Manufacturer />
          <Manufacturer />
        </div>
        <Pagination />
      </div>
    </>
  );
};
export default AllManufacturers;
