import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, LinearProgress, Typography } from "@material-ui/core";
import IconBasket from "../../../Icons/IconBasket";
import IconCheck from "../../../Icons/IconCheck";

const CampaignMeter = ({ context, addToCart = null }) => {

  const [progress, setProgress] = useState(0)
  useEffect(() => {
    if (context.maxLevel !== null) {
      const progress = Math.round((context.totalPrice / context.maxLevel.level) * 100)
      setProgress(progress > 100 ? 100 : progress)    
    }    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.totalPrice, context.maxLevel])
  

  return (
    <Fragment>
      
      <div className="head">
        <Button variant="outlined" color="primary">
          Zboží za {context.totalPrice.toFixed(2)} €
        </Button>
        <Button variant="outlined" color="primary">
          Aktuální sleva {context.currentLevel !== null ? context.currentLevel.bonus : 0}%
        </Button>
        <Button variant="outlined" color="primary">
          Ušetříte {context.currentLevel !== null ? ((context.totalPrice / 100) * context.currentLevel.bonus).toFixed(2) : 0 } €
        </Button>
        {addToCart !== null && <Button onClick={addToCart} variant="contained" color="primary" className="productToCart" endIcon={<IconBasket color="white" />}>
          Vložit do košíku {context.productCount} produktů
        </Button>}
      </div>      

      <div className="sale">
        <div className="progressHead">
          {context.campaign.levels.map((level) => (
            <div key={`${level.id}${Math.random()}`} className="progressText">
              <Typography component="h6" variant="h6">
                Sleva {level.bonus}%
              </Typography>
              <IconCheck color="#F50057" />
            </div>
          ))}
        </div>
        <div className="progressBorder">
          <LinearProgress variant="determinate" value={progress} className="progress" />
        </div>
      </div>

    </Fragment>
  );
};

CampaignMeter.propTypes = {
  context: PropTypes.object.isRequired,
  cart: PropTypes.object,
  addToCart: PropTypes.func,
};

export default CampaignMeter;
