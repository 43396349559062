import React, { useReducer, useEffect } from "react";
import axios from "axios";
import AuthContext from "./AuthContext";
import authReducer from "./AuthReducer";
import setAuthToken from "../../utility/setAuthToken";
import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS
} from "../Types";

const AuthState = props => {
  const initialState = {
    token: localStorage.getItem("jwtToken"),
    isAuthenticated: null,
    loading: true,
    user: null,
    error: null
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    if (state.loading) {
      loadUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Load User
  const loadUser = async () => {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      setAuthToken(token);
      try {
        const res = await axios.post("/auth/refresh");

        dispatch({
          type: USER_LOADED,
          payload: res.data.data
        });
      } catch (err) {
        dispatch({ type: AUTH_ERROR });
      }
    } else {
      // if no token, set loaded with no data
      dispatch({
        type: USER_LOADED,
        payload: null,
      });
    }
  };

  // Login User
  const login = async formData => {
    try {
      const res = await axios.post("/auth/login", formData);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data.data
      });
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        payload: err
      });
    }
  };

  // Logout
  const logout = () => dispatch({ type: LOGOUT });

  // Clear Errors
  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        user: state.user,
        error: state.error,
        loadUser,
        login,
        logout,
        clearErrors
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
