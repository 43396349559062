import React, { useContext, useEffect, useState } from "react";
import SignIn from "../UI/SignIn/SignIn";
import AuthContext from "../../context/Auth/AuthContext";

const Login = (props) => {

  const authContext = useContext(AuthContext);
  const [alert, setAlert] = useState(null);
  

  const { error, clearErrors, isAuthenticated } = authContext;

  useEffect(() => {
    if (isAuthenticated) {
      props.history.push("/");
    }

    if (error === "Invalid Credentials") {
      setAlert(error);
      clearErrors();
    }
    // eslint-disable-next-line
  }, [error, isAuthenticated, props.history]);

  return (
    <div className="container">
      <div className="content">
        <SignIn login={authContext.login} error={alert}  />
      </div>
    </div>
  );
};

export default Login;
