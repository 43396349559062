import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import SupplierSelect from '../../../SupplierSelect/SupplierSelect'
import { Button } from '@material-ui/core'
import ProductList from './ProductList'
import { Pagination } from '@material-ui/lab'
import IconBasket from '../../../Icons/IconBasket'
import CampaignMeter from './CampaignMeter'
import CampaignCartContext from '../../../../../context/CampaignCart/CampaignCartContext'
import CartContext from '../../../../../context/Cart/CartContext'

const CampaignValue = ({ campaign }) => {
  const ccContext = useContext(CampaignCartContext)
  const cartContext = useContext(CartContext)
  const [showSupplier, setShowSupplier] = useState(false)

  useEffect(() => {
    ccContext.load(campaign)
    if (cartContext.pharmacy.distributor_code.length > 0) {
      ccContext.setDistributorCode(cartContext.pharmacy.distributor_code[0].id)
      setShowSupplier(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addToCart = () => {
    cartContext.addCampaign({ 
      items: ccContext.items, 
      campaign,
      distributorCode: ccContext.distributorCode
     })
  }

  return (
    
    <div className="right">
          
      {(ccContext.campaign !== null &&  ccContext.maxLevel !== null) &&
        <CampaignMeter 
          context={ccContext} 
          addToCart={addToCart}
          />
      }      

      <div className="informationLine">
        {showSupplier && 
          <SupplierSelect 
            distributorCode={ccContext.distributorCode} 
            setDistributorCode={ccContext.setDistributorCode}
          />
        }
        {ccContext.nextLevel !== null ?
          <Button variant="outlined">Do další slevy zbývá {(parseInt(ccContext.nextLevel.level) - parseInt(ccContext.productCount))} ks
          </Button> :
          ccContext.topProduct && <Button variant="outlined">Dosáhli jste maximální slevy</Button>
        }        
      </div>

      <div className="kampaneProducts">
        <ProductList 
          products={campaign.products}
          context={ccContext}
          />
        <Button onClick={addToCart} variant="contained" color="primary" className="productToCart" endIcon={<IconBasket color="white" />}>
          Vložit do košíku {ccContext.productCount} produktů
        </Button>
        <Pagination />
      </div>
    </div>
    
  )
}

CampaignValue.propTypes = {
  campaign: PropTypes.object.isRequired,
}

export default CampaignValue
