import React from "react";
import { NavLink } from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const About = (props) => {
  return (
    <div className="container content">
      <div className="left">
        <List>
          <ListItem button selected component={NavLink} to="/o-projektu">
            <ListItemText primary="o projektu" />
            <ListItemIcon>
              <FontAwesomeIcon icon={faCheck} />
            </ListItemIcon>
          </ListItem>
          <ListItem button component={NavLink} to="/kontakt">
            <ListItemText primary="kontakt" />
            <ListItemIcon>
              <FontAwesomeIcon icon={faCheck} />
            </ListItemIcon>
          </ListItem>
          <ListItem button component={NavLink} to="/ochrana-osobnich-udaju">
            <ListItemText primary="ochrana osobních údajů" />
            <ListItemIcon>
              <FontAwesomeIcon icon={faCheck} />
            </ListItemIcon>
          </ListItem>
          <ListItem button component={NavLink} to="/obchodni-podminky">
            <ListItemText primary="obchodní podmínky" />
            <ListItemIcon>
              <FontAwesomeIcon icon={faCheck} />
            </ListItemIcon>
          </ListItem>
        </List>
      </div>
      <div className="right">
        <p className="infoHeading">O projektu</p>
        <p>
          At vero eos et molestiae consequatur, vel eum fugiat, quo ignorare vos arbitrer, sed ut calere ignem, nivem esse appetendum, alterum aspernandum sentiamus alii autem,
          quibus ego assentior, cum memoriter, tum etiam ac ratione intellegi posse et molestiae consequatur, vel eum iure reprehenderit, qui officia. In quo aut interrogari ut
          placet, inquam tum dicere exorsus est primum igitur, quid sit numeranda nec segniorem ad naturam aut perferendis doloribus asperiores repellat hanc ego cum soluta nobis
          est laborum et quasi architecto beatae vitae sine causa, nollem me ab illo inventore veritatis.
        </p>
        <p>
          Laudem et quas molestias excepturi sint, obcaecati cupiditate non emolumento aliquo, sed animo etiam erga nos causae confidere, sed quia voluptas assumenda est, omnis
          iste natus error sit sentiri haec putat, ut de homine sensibus reliqui nihil impedit, quo minus id, de commodis suis cogitarent?.
        </p>
        <p>
          At vero eos et molestiae consequatur, vel eum fugiat, quo ignorare vos arbitrer, sed ut calere ignem, nivem esse appetendum, alterum aspernandum sentiamus alii autem,
          quibus ego assentior, cum memoriter, tum etiam ac ratione intellegi posse et molestiae consequatur, vel eum iure reprehenderit, qui officia. In quo aut interrogari ut
          placet, inquam tum dicere exorsus est primum igitur, quid sit numeranda nec segniorem ad naturam aut perferendis doloribus asperiores repellat hanc ego cum soluta nobis
          est laborum et quasi architecto beatae vitae sine causa, nollem me ab illo inventore veritatis.
        </p>
        <p>
          Laudem et quas molestias excepturi sint, obcaecati cupiditate non emolumento aliquo, sed animo etiam erga nos causae confidere, sed quia voluptas assumenda est, omnis
          iste natus error sit sentiri haec putat, ut de homine sensibus reliqui nihil impedit, quo minus id, de commodis suis cogitarent?.
        </p>
        <p>
          At vero eos et molestiae consequatur, vel eum fugiat, quo ignorare vos arbitrer, sed ut calere ignem, nivem esse appetendum, alterum aspernandum sentiamus alii autem,
          quibus ego assentior, cum memoriter, tum etiam ac ratione intellegi posse et molestiae consequatur, vel eum iure reprehenderit, qui officia. In quo aut interrogari ut
          placet, inquam tum dicere exorsus est primum igitur, quid sit numeranda nec segniorem ad naturam aut perferendis doloribus asperiores repellat hanc ego cum soluta nobis
          est laborum et quasi architecto beatae vitae sine causa, nollem me ab illo inventore veritatis.
        </p>
        <p>
          Laudem et quas molestias excepturi sint, obcaecati cupiditate non emolumento aliquo, sed animo etiam erga nos causae confidere, sed quia voluptas assumenda est, omnis
          iste natus error sit sentiri haec putat, ut de homine sensibus reliqui nihil impedit, quo minus id, de commodis suis cogitarent?.
        </p>
        <p>
          At vero eos et molestiae consequatur, vel eum fugiat, quo ignorare vos arbitrer, sed ut calere ignem, nivem esse appetendum, alterum aspernandum sentiamus alii autem,
          quibus ego assentior, cum memoriter, tum etiam ac ratione intellegi posse et molestiae consequatur, vel eum iure reprehenderit, qui officia. In quo aut interrogari ut
          placet, inquam tum dicere exorsus est primum igitur, quid sit numeranda nec segniorem ad naturam aut perferendis doloribus asperiores repellat hanc ego cum soluta nobis
          est laborum et quasi architecto beatae vitae sine causa, nollem me ab illo inventore veritatis.
        </p>
        <p>
          Laudem et quas molestias excepturi sint, obcaecati cupiditate non emolumento aliquo, sed animo etiam erga nos causae confidere, sed quia voluptas assumenda est, omnis
          iste natus error sit sentiri haec putat, ut de homine sensibus reliqui nihil impedit, quo minus id, de commodis suis cogitarent?.
        </p>
        <p>
          At vero eos et molestiae consequatur, vel eum fugiat, quo ignorare vos arbitrer, sed ut calere ignem, nivem esse appetendum, alterum aspernandum sentiamus alii autem,
          quibus ego assentior, cum memoriter, tum etiam ac ratione intellegi posse et molestiae consequatur, vel eum iure reprehenderit, qui officia. In quo aut interrogari ut
          placet, inquam tum dicere exorsus est primum igitur, quid sit numeranda nec segniorem ad naturam aut perferendis doloribus asperiores repellat hanc ego cum soluta nobis
          est laborum et quasi architecto beatae vitae sine causa, nollem me ab illo inventore veritatis.
        </p>
        <p>
          Laudem et quas molestias excepturi sint, obcaecati cupiditate non emolumento aliquo, sed animo etiam erga nos causae confidere, sed quia voluptas assumenda est, omnis
          iste natus error sit sentiri haec putat, ut de homine sensibus reliqui nihil impedit, quo minus id, de commodis suis cogitarent?.
        </p>
      </div>
    </div>
  );
};

export default About;
