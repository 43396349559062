import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import Axios from "axios";


const PharmacyAddDistrict = ({ value, setValue }) => {

  const [items, setItems] = useState([]);

  useEffect(() => {
    loadItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const loadItems = async() => {
    Axios.get("/pharmacy/district")
    .then((response) => {
      setItems(response.data.data)      
    })
    .catch(err => console.log("Api call err: ", err))  
  }

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl className="selectButton">
        <InputLabel id="pharmacy-district-select-label">Okres</InputLabel>
        <Select
          labelId="pharmacy-district-select-label"
          id="pharmacy-district-select"
          value={value}
          onChange={handleChange}
          variant="outlined"
        >
          {items.map((item) => <MenuItem key="{item.name}" value={item.name}>{item.name}</MenuItem>)}
        </Select>
      </FormControl>
  )
}
PharmacyAddDistrict.prototype = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
}

export default PharmacyAddDistrict;