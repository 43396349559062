export default (typeId) => {
    switch(typeId) {
        case 1: return 'UNIT';
        case 2: return 'VALUE';
        case 3: return 'GROWTH';
        case 4: return 'COMBI';
        case 5: return 'ABC_R';
        case 6: return 'UNITFREE';
        case 7: return 'VALUEFREE';
        default: return undefined;
    }
}

