import React, { useState } from "react";
import { Typography, Tabs, Tab, Button} from "@material-ui/core";
import UserAccount from "../UI/User/UserAccount";
import PharmacyList from "../UI/User/PharmacyList";
import PharmacyAdd from "../UI/User/PharmacyAdd";
import PharmacyEdit from "../UI/User/PharmacyEdit";



const User = () => {

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [pageIndex, setPageIndex] = useState(1);
 
  const handleChange = (event, newValue) => {
    setPageIndex(newValue);
  };

  const getTabContent = (index) => {
    switch(index) {
      case USER_ACTION_PHARMACY_LIST: return <PharmacyList />
      case USER_ACTION_PHARMACY_ADD: return <PharmacyAdd setPageIndex={setPageIndex} />
      case USER_ACTION_PHARMACY_EDIT: return <PharmacyEdit />
      default: return <UserAccount />
    }
  }
  const getTabIndex = () => {
    return pageIndex === USER_ACTION_ACCOUNT ? 0 : 1
  }

  return (
    <div className="container">
      <Typography component="h5" variant="h5">
        Uživatel
      </Typography>     
          
      <Tabs className="tabHeader" value={getTabIndex()} indicatorColor="secondary" textColor="secondary" onChange={handleChange} aria-label="simple tabs example">
        <Tab label="Úživatelský účet" {...a11yProps(USER_ACTION_ACCOUNT)} />
        <Tab label="lékárny" {...a11yProps(USER_ACTION_PHARMACY_LIST)} />
      </Tabs>

      {(pageIndex === USER_ACTION_PHARMACY_LIST &&
        <div className="addPharmacy">
          <Button variant="contained" className="rounded icon white" icon="plus" />
          <Button text="Přidat novou lékárnu" className="white regular" variant="contained" onClick={() => setPageIndex(USER_ACTION_PHARMACY_ADD)} />
        </div>
      )}

      { getTabContent(pageIndex) }

    </div>
  );
};
export default User;

export const USER_ACTION_ACCOUNT = 0;
export const USER_ACTION_PHARMACY_LIST = 1;
export const USER_ACTION_PHARMACY_ADD = 2;
export const USER_ACTION_PHARMACY_EDIT = 3;

