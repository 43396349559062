import React from "react";
import { Typography, Box } from "@material-ui/core";
import { Link } from "react-router-dom";

import Logo from "../../assets/images/logo-UI.svg";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footerInner">
          <div className="footerLogo">
            <img src={Logo} alt="logo" />
          </div>

          <div className="footerNav">
            <nav>
              <ul>
                <Link to="/about">
                  <li>o projektu</li>
                </Link>
                <Link to="/kontakt">
                  <li>kontakt</li>
                </Link>
                <Link to="/ochrana-osobnich-udaju">
                  <li>ochrana osobních údajů</li>
                </Link>
                <Link to="/obchodni-podminky">
                  <li>obchodní podmínky</li>
                </Link>
              </ul>
            </nav>
          </div>
        </div>

        <div className="footerCopy">
          <Typography component="div">
            <Box textAlign="right" fontWeight="fontWeightMedium">
              Copyright 2020 e-objednavka.sk
            </Box>
          </Typography>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
