import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import { Typography, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import Product from "../Products/Product";

const SwiperProducts = (props) => {
  const sliderID = props.sliderID ? props.sliderID : "noIDset";
  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      var filterParams = {
        pageSize: 10,
        pageNumber: 0,
        sortField: "inserted",
        sortDirection: "desc",
      };
      const res = await Axios.get("/product", {
        params: filterParams,
      });
      setRows(await res.data.data.data);
      setLoaded(true);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div id={sliderID} className="swiperContainer">
      <div className="productsHeadline">
        <Typography component="h5">Nové akce přímo pro vás</Typography>
        <Button variant="contained" component={Link} to="/products">
          Zobrazit vše
        </Button>
      </div>
      <Swiper
        className="swiper"
        spaceBetween={10}
        slidesPerView={5}
        loop={false}
        navigation={{
          nextEl: `#${sliderID} .swiperNext`,
          prevEl: `#${sliderID} .swiperPrev`,
        }}
        /* onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)} */
      >
        {loaded &&
          rows.map((item, index) => (
            <SwiperSlide key={`slide${index}`}>
              <Product badge="campaign" key={item.id} data={item} />
            </SwiperSlide>
          ))}
      </Swiper>
      <div className="swiperNavigation">
        <div className="swiperNext">
          <FontAwesomeIcon icon={faAngleRight} />
        </div>
        <div className="swiperPrev">
          <FontAwesomeIcon icon={faAngleLeft} />
        </div>
      </div>
    </div>
  );
};
export default SwiperProducts;
