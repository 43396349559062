import React from "react";
import { Typography, Button } from "@material-ui/core";

const CampaignAction = () => {
  return (
    <div className="campaignAction">
      <Typography component="h6" variant="h6">
        Kampaň 10 kusů + 2 zdarma
      </Typography>
      <Button variant="contained">Jít do kampaně</Button>
    </div>
  );
};
export default CampaignAction;
