import React, { useReducer, useEffect } from "react";
import axios from "axios";

import ProductContext from "./ProductContext";
import ProductReducer from "./ProductReducer";

import {
  PRODUCT_DETAIL,
  PRODUCT_DETAIL_LOADING,
  PRODUCT_ERROR,
  PRODUCT_FILTER,
  PRODUCT_LIMIT,
  PRODUCT_PAGE,
  PRODUCT_ROWS_LOADING,
  PRODUCT_SEARCH,
  PRODUCT_SORT,
} from "../Types";

const ProductState = (props) => {
  const initialState = {
    current: null,
    rows: [],
    error: null,
    loadingRows: false,
    loadingDetail: false,
    limitOptions: [10, 25, 50, 100],
    limit: 12,
    rowCount: 0,
    page: 0,
    sortField: "name",
    sortDirection: "asc",
    filter: {
      fulltext: "",
      brandId: "",
      priceFrom: "",
      priceTo: "",
    },
    filterList: [],
  };

  const [state, dispatch] = useReducer(ProductReducer, initialState);

  useEffect(
    () => {
      search();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.limit, state.page, state.filter, state.sortField, state.sortDirection]
  );

  // Set Loading
  const setRowsLoading = () => dispatch({ type: PRODUCT_ROWS_LOADING });
  const setDetailLoading = () => dispatch({ type: PRODUCT_DETAIL_LOADING });

  // Search
  const search = async () => {
    setRowsLoading();
    try {
      var filterParams = {
        pageSize: state.limit,
        pageNumber: state.page,
        sortField: state.sortField,
        sortDirection: state.sortDirection,
      };
      // eslint-disable-next-line array-callback-return
      Object.entries(state.filter).map(function (el) {
        if (el[1] !== "") {
          filterParams["filter_" + el[0].toLowerCase()] = el[1];
        }
      });
      const res = await axios.get("/product", {
        params: filterParams,
      });
      dispatch({
        type: PRODUCT_SEARCH,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: PRODUCT_ERROR,
        payload: err,
      });
    }
  };

  const detail = async (id) => {
    setDetailLoading();
    try {
      const res = await axios.get("/product/" + id);
      dispatch({
        type: PRODUCT_DETAIL,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: PRODUCT_ERROR,
        payload: err,
      });
    }
  };

  const changeRowsPerPage = (rowCount) => {
    return dispatch({
      type: PRODUCT_LIMIT,
      payload: rowCount,
    });
  };

  const changePage = (pageIndex) => {
    return dispatch({
      type: PRODUCT_PAGE,
      payload: pageIndex,
    });
  };

  const setFilter = (filter) => {
    return dispatch({
      type: PRODUCT_FILTER,
      payload: filter,
    });
  };

  const setSort = (field, direction) => {
    return dispatch({
      type: PRODUCT_SORT,
      payload: {
        field: field,
        direction: direction,
      },
    });
  };

  return (
    <ProductContext.Provider
      value={{
        loadingRows: state.loadingRows,
        loadingDetail: state.loadingDetail,
        loadingUserList: state.loadingUserList,
        searchProceed: state.searchProceed,
        current: state.current,
        rows: state.rows,
        error: state.error,
        limitOptions: state.limitOptions,
        limit: state.limit,
        rowCount: state.rowCount,
        page: state.page,
        sortField: state.sortField,
        sortDirection: state.sortDirection,
        filter: state.filter,
        filterList: state.filterList,

        search,
        detail,
        changeRowsPerPage,
        changePage,
        setFilter,
        setSort,
      }}
    >
      {props.children}
    </ProductContext.Provider>
  );
};

export default ProductState;
