import React from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types'
import moment from 'moment'
import { Typography, Button } from "@material-ui/core";
import placeholder from "../../../../assets/images/Placeholder.svg";
import campaignImage from "../../../../assets/images/Campaing.jpg";

const BigCampaign = ({ data }) => {
  return (
    <div className="bigCampaign">
      <div className="content">
        <h2>{data.name}</h2>
        <Typography component="p">Platné do {moment(data.valid_to).format('DD.MM.YYYY') }</Typography>
        <Link to={`/campaigns/${data?.id}`}>
          <Button variant="outlined">Zobrazit</Button>
        </Link>       
        <div className="lowerText">
          <Typography component="h6" className="lowerTextHeadline">
            Claim
          </Typography>
          <Typography component="p">At magnum periculum adiit in oculis quidem facinut, ut summo bono dolorem ipsum per se repellere.</Typography>
        </div>
      </div>
      <img src={placeholder} className="placeholder" alt="big campaign" />
      <img src={campaignImage} alt="big campaign" />
    </div>
  );
};

BigCampaign.propTypes = {  
  data: PropTypes.object.isRequired,
}


export default BigCampaign;
