import React, {  useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import Axios from "axios";

const PharmacyAddDistributor = ({ value, setValue, pharmacy }) => {

  const [items, setItems] = useState([]);

  useEffect(() => {
    loadItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacy])
  
  const loadItems = async() => {
    Axios.get(`/pharmacy/${pharmacy}/distributor`)
    .then((response) => {
      setItems(response.data.data)      
    })
    .catch(err => console.log("Api call err: ", err))  
  }

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl className="selectButton">
        <InputLabel id="pharmacy-distributor-select-label">Preferovaný distributor</InputLabel>
        <Select
          labelId="pharmacy-distributor-select-label"
          id="pharmacy-distributor-select"
          value={value}
          onChange={handleChange}
          variant="outlined"
        >
          {items.map((item) => <MenuItem value={item.id}>{item.info_code} - {item.name}</MenuItem>)}
        </Select>
      </FormControl>
  )
}
PharmacyAddDistributor.prototype = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  pharmacy: PropTypes.number.isRequired,
}

export default PharmacyAddDistributor;