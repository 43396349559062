import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const AutoComplete = () => {
  const data = [
    {
      title: "Všechny objednávky",
    },
    {
      title: "Dodavatel 0",
    },
    {
      title: "Dodavatel 1",
    },
    {
      title: "Dodavatel 2",
    },
    {
      title: "Dodavatel 3",
    },
  ];

  const [value, setValue] = useState(data[0]);
  const [inputValue, setInputValue] = useState("");

  return (
    <Autocomplete
      id="autocomplete"
      className="autocomplete"
      options={data}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => <TextField {...params} label="Číslo objednávky" variant="outlined" />}
    />
  );
};
export default AutoComplete;
