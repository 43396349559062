import React, { useState, useEffect } from "react";
import { Typography, Chip, Button, Popover, TextField, Backdrop } from "@material-ui/core";

const FilterSelected = (props) => {
  const [filterButton, setFilterButton] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [chips, setChips] = useState(1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState("Kupuji každý týden");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (chips === 1) {
      setFilterButton(false);
    }
  }, [chips]);

  return (
    <div className="filterSelected">
      <Typography component="p">Filtr produktů:</Typography>
      <Chip label="Všechny produkty" />
      {chips !== 0 ? <Chip label={props.chipLabel} /> : null}
      {filterButton ? (
        <>
          <Button color="primary" variant="outlined" className="filterButton" aria-describedby={id} onClick={handleClick}>
            uložit filtr
          </Button>
          <div style={{ position: "relative", zIndex: "1000" }}>
            <Backdrop open={open} onClick={handleClose}>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Typography component="p">Uložit filtr</Typography>
                <TextField fullWidth id="standard-multiline-flexible" label="Název filtru" multiline rowsMax={4} value={value} onChange={handleChange} />
                <div className="filterButtonGroup">
                  <Button variant="contained" onClick={handleClose}>
                    zrušit
                  </Button>
                  <Button variant="contained" color="primary">
                    uložit
                  </Button>
                </div>
              </Popover>
            </Backdrop>
          </div>
        </>
      ) : (
        <>
          <Button variant="outlined" className="filterButton close" aria-describedby={id} onClick={handleClick}>
            smazat filtr
          </Button>
          <div style={{ position: "relative", zIndex: "1000" }}>
            <Backdrop open={open} onClick={handleClose}>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Typography component="p">Smazat filtr</Typography>
                <Typography component="p" className="filterName">
                  Kupuji každý týden
                </Typography>
                <div className="filterButtonGroup">
                  <Button variant="contained" onClick={handleClose}>
                    zrušit
                  </Button>
                  <Button variant="contained" color="primary" className="close">
                    smazat
                  </Button>
                </div>
              </Popover>
            </Backdrop>
          </div>
        </>
      )}
    </div>
  );
};
export default FilterSelected;
