import React from "react";
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import image from "../../../assets/images/Rozcestnik-UI.png";
import image1 from "../../../assets/images/Rozcestnik-UI1.png";
import image2 from "../../../assets/images/Rozcestnik-UI2.png";

const Promo = ({ imageIndex, link, title, count, countDescription }) => {
  const selectImage = imageIndex === "1" ? image :imageIndex === "2" ? image2 : imageIndex === "3" ? image1 : null;

  return (
    <div className="promo">
      <div className="promoImage">
        <Link to={`/${link}`} title={title}>
          <img src={selectImage} alt="promo" />
        </Link>
      </div>
      <div className="promoText">
        <Typography component="p">{title}</Typography>
        <Typography component={Link} to={`/${link}`}>
          {count} {countDescription}
        </Typography>
      </div>
    </div>
  );
};

Promo.protoTypes = {
  imageIndex: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  countDescription: PropTypes.string.isRequired,
};

export default Promo;
