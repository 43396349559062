import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from 'moment'
import { Typography, Button } from "@material-ui/core";
import campaignImage from "../../../../assets/images/CampaingSmall.jpg";
import Placeholder from "../../../../assets/images/Placeholder.svg";
import CampaignType from "../CampaignType";

const SmallCampaign = ({ data }) => {
  return (
    <div className="smallCampaignBanner">
      <div className="content">
        <Link to={`/campaigns/${data.id}`}>
          <Typography component="h5">{data.name}</Typography>
          <Typography component="p">
            Platné do {moment(data.valid_to).format('DD.MM.YYYY') }<br />
            (Type={CampaignType(data.type_id)})
          </Typography>
          <Button variant="outlined">zobrazit</Button>
        </Link>
      </div>
      <img src={Placeholder} className="placeholder" alt="small campaign" />
      <img src={campaignImage} alt="small campaign" />
    </div>
  );
};

SmallCampaign.protoTypes = {
  data: PropTypes.object.isRequired,
};

export default SmallCampaign;
