import React, { useContext } from 'react'
import { Link } from "react-router-dom";
import { Badge, Button } from '@material-ui/core'
import IconCart from '../Icons/IconCart'
import CartContext from "../../../context/Cart/CartContext";

const CartButton = () => {
  const cartContext = useContext(CartContext);

  return (
    <Badge color="primary" badgeContent={cartContext.productCount}>
      <Button variant="outlined" color="primary" component={Link} to="/cart">
        <IconCart color="rgb(0, 129, 223)" />
      </Button>
    </Badge>
  )
}

export default CartButton
