import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Typography, Button } from "@material-ui/core";
import Manufacturer from "./Manufacturer";
import AuthContext from "../../../context/Auth/AuthContext";
import BrandContext from "../../../context/Brand/BrandContext";

const Manufacturers = () => {
  const authContext = useContext(AuthContext);
  const brandContext = useContext(BrandContext);

  useEffect(() => {
    if (authContext.isAuthenticated) {
      brandContext.setBrandsItems();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const brandList = brandContext.items ? brandContext.items.slice(0, 8) : null;

  return (
    <div className="manufacturers">
      <div className="manufacturersHead">
        <Typography component="h5">Výrobci</Typography>
        <Link to="/manufacturer">
          <Button>Zobrazit vše</Button>
        </Link>
      </div>
      <div className="manufacturersBlock">{brandList && brandList.map((item) => <Manufacturer key={item.id} brand={item} />)}</div>
    </div>
  );
};
export default Manufacturers;
