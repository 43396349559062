import { BRAND_ITEMS } from "../Types";

export default (state, action) => {
  switch (action.type) {
    case BRAND_ITEMS:
      // console.log("action", action.payload);
      return {
        ...state,
        items: action.payload,
      };
    default:
      return {
        state,
      };
  }
};
