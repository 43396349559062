import React, { useState, useEffect, useContext } from "react";
//import { Route, Switch, useLocation } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import AuthContext from "../../context/Auth/AuthContext";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Layout = (props) => {
  const [pageClass, setPageClass] = useState(null);

  const location = useLocation();
  let locationName = location && location.pathname;
  let locationNameNew = locationName.replace(/[/-]/, "");
  let bodyClass = locationNameNew.charAt(0).toUpperCase() + locationNameNew.slice(1);
  // let bodyClassSliced = bodyClass.substr(0, bodyClass.lastIndexOf("/"));
  //console.log("location", location, "locationClass", bodyClassSliced);

  useEffect(() => {
    setPageClass(bodyClass);

    if (location && location.pathname === "/login") {
      setPageClass("Login");
    } else if (
      (location && location.pathname === "/") ||
      (location && location.pathname === "/manufacturers") ||
      (location && location.pathname === "/campaigns")
    ) {
      setPageClass("Homepage");
    } else if (location && location.pathname.indexOf("/detail/") > -1) {
      setPageClass("Detail");
    } else if (location && location.pathname.indexOf("/campaigns/") > -1) {
      setPageClass("Campaigns");
    } else if (location && location.pathname.indexOf("/user-account") > -1) {
      setPageClass("User");
    } else if (location && location.pathname.indexOf("/cart") > -1) {
      setPageClass("Basket");
    } else if (
      (location && location.pathname === "/about") ||
      (location && location.pathname === "/kontakt") ||
      (location && location.pathname === "/ochrana-osobnich-udaju") ||
      (location && location.pathname === "/obchodni-podminky")
    ) {
      setPageClass("Info");
    }
  }, [bodyClass, location]);

  //console.log("layout", props);
  const authContext = useContext(AuthContext);

  /*const notify = () =>
    toast.success("🦄 Wow so easy!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });    */
  return (
    <>
      <Helmet>
        <body className={pageClass} />
      </Helmet>
      <Header logedIn={authContext.isAuthenticated} />
      {props.children}
      {/* <button onClick={notify}>Notify !</button> */}
      <Footer />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Layout;
