import React, { useEffect, useContext } from "react";
import { Slider, Typography } from "@material-ui/core";
import ProductContext from "../../../context/Product/ProductContext";

const marks = [
  {
    value: 0,
    label: "0€",
  },
  {
    value: 200,
  },
  {
    value: 400,
  },
  {
    value: 600,
  },
  {
    value: 800,
  },
  {
    value: 1000,
  },
  {
    value: 1200,
  },
  {
    value: 1400,
  },
  {
    value: 1600,
  },
  {
    value: 1800,
  },
  {
    value: 2000,
    label: "2000€",
  },
];

function valuetext(value) {
  return `${value}°C`;
}

const FilterPrice = () => {
  const productContext = useContext(ProductContext);
  const [priceRange, setPricerange] = React.useState([600, 1000]);

  const handleChange = (event, newValue) => {
    setPricerange(newValue);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      productContext.setFilter({ priceFrom: priceRange[0], priceTo: priceRange[1] });
    }, 300);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceRange]);

  return (
    <>
      <div className="rangeSlider">
        <div className="rangeSliderHead">
          <Typography component="p" className="price">
            Cena
          </Typography>
          <Typography component="p" className="priceValue">
            {priceRange[0] + " - " + priceRange[1] + " €"}
          </Typography>
        </div>
        <Slider
          defaultValue={2000}
          value={priceRange}
          min={0}
          max={2000}
          step={200}
          marks={marks}
          onChange={handleChange}
          valueLabelDisplay="on"
          aria-labelledby="range-slider"
          getAriaValueText={valuetext}
        />
      </div>
    </>
  );
};
export default FilterPrice;
