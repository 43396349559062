import React, { useContext, useEffect, useState } from "react";
import { InputBase } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ProductContext from "../../../context/Product/ProductContext";

const SearchManufacturer = (props) => {
  const productContext = useContext(ProductContext);
  const [searchValue, setsearchValue] = useState("");

  const searchNow = (ev) => {
    if (ev) ev.preventDefault();
    productContext.setFilter({ fulltext: searchValue });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchValue.length > 1) {
        searchNow();
      }
    }, 300);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <div className="search searchManufacturer">
      <div className="searchField">
        <form onSubmit={searchNow}>
          <div className="searchIcon">
            <FontAwesomeIcon icon={faSearch} />
          </div>
          <InputBase
            id="SearchManufacturer"
            type="search"
            placeholder="Hledat výrobce"
            inputProps={{ "aria-label": "search" }}
            value={searchValue}
            onChange={(e) => setsearchValue(e.target.value)}
          />
        </form>
      </div>
    </div>
  );
};

export default SearchManufacturer;
