import React, { useContext, useEffect } from "react";
import { Typography } from "@material-ui/core";

import Search from "../UI/Search/Search";
import SearchManufacturer from "../UI/Search/SearchManufacturer";
import FilterManufacturers from "../UI/Filters/FilterManufacturers";
import FilterPrice from "../UI/Filters/FilterPrice";
// import FilterSpecial from "../UI/Filters/FilterSpecial";
// import FilterSaved from "../UI/Filters/FilterSaved";
import FilterSelected from "../UI/Filters/FilterSelected";
import Product from "../UI/Products/Product";
import { default as Pagination } from "../UI/Pagination/Pagination";
import AuthContext from "../../context/Auth/AuthContext";
import ProductContext from "../../context/Product/ProductContext";
import Progress from "../UI/Progress/Progress";

const Products = () => {
  const authContext = useContext(AuthContext);
  const productContext = useContext(ProductContext);

  useEffect(() => {
    if (authContext.isAuthenticated) {
      productContext.search();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="container">
        <Search />
        <div className="productPage">
          <div className="productMenu">
            <Typography variant="h5" component="h5">
              Produkty
            </Typography>
            <SearchManufacturer placeholder="Hledat výrobce" />
            <FilterManufacturers />
            <FilterPrice />
            {/* <FilterSpecial />
            <FilterSpecial />
            <FilterSaved /> */}
          </div>
          <div className="productList">
            <FilterSelected chipLabel="Akce" />
            <div className="products">
              {productContext.loadingRows ? (
                <Progress />
              ) : (
                productContext.rows.map((item) => <Product badge="campaign" key={item.id} data={item} />)
              )}
            </div>
            <Pagination />
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
