import React, { useContext } from "react";
import Search from "../UI/Search/Search";
import { Typography } from "@material-ui/core";
import GrandTotal from "../UI/Cart/GrandTotal";
import CartContext from "../../context/Cart/CartContext";
import CampaignContext from "../../context/Campaign/CampaignContext";
import CampaignCartState from "../../context/CampaignCart/CampaignCartState";
import CartValue from "../UI/Campaigns/Type/Value/Cart"
import CartUnit from "../UI/Campaigns/Type/Unit/Cart"
import CartUnitFree from "../UI/Campaigns/Type/UnitFree/Cart"


const Cart = () => {
  const campaignContext = useContext(CampaignContext)
  const cartContext = useContext(CartContext)

  const getCartByType = (cart) => {
    switch (cart.type_id) {
      case 1: // unit
        return (<CartUnit cart={cart} campaign={campaignContext.rows.find(row => row.id === cart.id)} />)
      case 2: // value
        return (<CartValue cart={cart} campaign={campaignContext.rows.find(row => row.id === cart.id)} />)
      case 6: // unitfree
        return (<CartUnitFree cart={cart} campaign={campaignContext.rows.find(row => row.id === cart.id)} />)
      default:
        return
    }
  }

  return (
    <div className="container">
      <Search />
      <div className="kosikPage">
        <div className="left">
          <Typography component="h4" variant="h4" gutterBottom>
            Košík
          </Typography>
          <Typography component="p" className="mbt" gutterBottom>
            Nejprodávanější
          </Typography>
          {/*
          <Product badge="campaign" />
          <Product badge="campaign" />
          <Product badge="campaign" />
          */}
        </div>

        <div className="right">

          {!campaignContext.loadingRows && cartContext.campaigns?.map(cart => 
            <CampaignCartState key={cart.id}>
              {getCartByType(cart)}
            </CampaignCartState>
          )}

{/*
          
          <div className="content">
            <Sale showTime />

            <div className="informationLine">
              <Button variant="outlined" color="primary" className="primary">
                Zboží za 1279 €
              </Button>
              <Button variant="outlined" color="primary" className="primary">
                Aktuální sleva 10%
              </Button>
              <Button variant="outlined" color="primary" className="primary">
                Ušetříte 279 €
              </Button>
              <Button variant="outlined">Do další slevy zbývá 10 produktů</Button>
            </div>

            <div className="kosikProducts">
              <div className="kosikProductsBody">
                <ProductListItem rabat rabatButton />
                <div className="line">
                  <Button variant="contained">Odebrat kampaň z košíku</Button>
                  <Button variant="contained" color="primary">
                    Otevřít znovu kampaň
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="content rest">
            <div className="line bottom">
              <Typography component="h6" variant="h6">
                Ostatní produkty
              </Typography>
              <SupplierSelect />
            </div>
            <div className="kosikProducts">
              <div className="kosikProductsBody">
                <ProductListItem />
                <Button variant="contained">Odebrat produkty z košíku</Button>
              </div>
            </div>
          </div>

*/}

          <GrandTotal 
            checkout={cartContext.checkout}
            totalPrice={cartContext.totalPrice}
            totalDiscount={cartContext.totalDiscount}
          />
        </div>
      </div>
    </div>
  );
};
export default Cart;
