import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, Card, CardContent, Typography, TextField, Button, Divider } from "@material-ui/core";

const SignIn = ({ login, error }) => {

  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const { email, password } = user;

  const onChange = (e) => setUser({ ...user, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      // setAlert("Please fill in all fields", "danger");
    } else {
      login({
        email,
        password,
      });
    }
  };

  return (
    <Card className="SignIn">
      <CardContent>
      <form autoComplete="off" onSubmit={onSubmit}>
        <Grid container direction="row" justify="center" alignItems="center" spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h5">
              Přihlášení
            </Typography>
          </Grid>
          <Grid item xs={12}>
              <TextField fullWidth id="loginEmail" label="Email" type="email" name="email" value={email} onChange={onChange} required />
          </Grid>
          <Grid item xs={12}>
              <TextField fullWidth id="loginPassword" label="Heslo" type="password" name="password" value={password} onChange={onChange} required />            
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth variant="contained" color="primary" type="submit">
              Přihlásit
            </Button>
          </Grid>
          <Divider style={{ width: "100%" }} />
          <Grid item xs={12}>
            <Button fullWidth variant="contained">
              Registrovat
            </Button>
          </Grid>
        </Grid>
      </form>
      </CardContent>
    </Card>
  );
};

SignIn.prototype = {
  login: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
}

export default SignIn;
