import React from "react";

const IconBasket = (props) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="shopping-basket"
      className="svg-inline--fa fa-shopping-basket fa-w-18"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
    >
      <path
        fill={props.color}
        d="M564 192h-76.875L347.893 37.297c-5.91-6.568-16.027-7.101-22.596-1.189s-7.101 16.028-1.189 22.596L444.075 192h-312.15L251.893 58.703c5.912-6.567 5.379-16.685-1.189-22.596-6.569-5.912-16.686-5.38-22.596 1.189L88.875 192H12c-6.627 0-12 5.373-12 12v8c0 6.627 5.373 12 12 12h16.444L58.25 438.603C61.546 462.334 81.836 480 105.794 480h364.412c23.958 0 44.248-17.666 47.544-41.397L547.556 224H564c6.627 0 12-5.373 12-12v-8c0-6.627-5.373-12-12-12zm-77.946 242.201c-1.093 7.867-7.906 13.799-15.848 13.799H105.794c-7.942 0-14.755-5.932-15.848-13.799L60.752 224h454.497l-29.195 210.201zM304 280v112c0 8.837-7.163 16-16 16-8.836 0-16-7.163-16-16V280c0-8.837 7.164-16 16-16 8.837 0 16 7.163 16 16zm112 0v112c0 8.837-7.163 16-16 16s-16-7.163-16-16V280c0-8.837 7.163-16 16-16s16 7.163 16 16zm-224 0v112c0 8.837-7.164 16-16 16s-16-7.163-16-16V280c0-8.837 7.164-16 16-16s16 7.163 16 16z"
      ></path>
    </svg>
  );
};
export default IconBasket;
