import React from "react";
import { Typography, TableContainer, Table, TableBody, TableCell, TableRow, Paper } from "@material-ui/core";

const Specifications = () => {
  return (
    <div className="specification">
      <Typography component="h6" variant="h6">
        Technické údaje:
      </Typography>

      <TableContainer component={Paper}>
        <Table className="table" aria-label="table">
          <TableBody>
            <TableRow>
              <TableCell component="th">Výrobce:</TableCell>
              <TableCell align="left">Vileda</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Barva:
              </TableCell>
              <TableCell align="left">červeno-šedá</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Krycí materiál:
              </TableCell>
              <TableCell align="left">mikrovlákno</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default Specifications;
