import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import BigCampaign from "../UI/Campaigns/BigCampaign/BigCampaign";
import SmallCampaign from "../UI/Campaigns/SmallCampaign/SmallCampaign";
import Promo from "../UI/Promo/Promo";
import Search from "../UI/Search/Search";
import SwiperProduct from "../UI/Swiper/SwiperProduct";
import Manufacturers from "../UI/Manufacturers/Manufacturers";
import AuthContext from "../../context/Auth/AuthContext";
import CartContext from "../../context/Cart/CartContext";


const Homepage = () => {
  const authContext = useContext(AuthContext);
  const cartContext = useContext(CartContext);

  const [campaigns, setCampaigns] = useState([]);
  const [counts, setCounts] = useState([]);

  useEffect(() => {
    loadCampaigns()
    loadCounts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadCampaigns = async() => {
    if (cartContext.pharmacy !== null) {
      Axios.get(`/homepage/${cartContext.pharmacy.info.id}/campaigns`)
      .then((response) => {
        setCampaigns(response.data.data)      
      })
      .catch(err => console.log("Api call err: ", err))  
    }    
  }
  const loadCounts = async() => {
    if (cartContext.pharmacy !== null) {
      Axios.get(`/homepage/${cartContext.pharmacy.info.id}/counts`)
      .then((response) => {
        setCounts(response.data.data)      
      })
      .catch(err => console.log("Api call err: ", err))  
    }    
  }

  return (
    <>
      <div className="campaigns">
        {campaigns[0] && <BigCampaign data={campaigns[0]} /> }
        <div className="smallCampaign">
          {campaigns[1] && <SmallCampaign data={campaigns[1]} /> }
          {campaigns[2] && <SmallCampaign data={campaigns[2]} /> }
          {campaigns[3] && <SmallCampaign data={campaigns[3]} /> }
        </div>
      </div>

      <div className="container" style={{ paddingTop: "65px" }}>
        <div className="promoBlock">
          <Promo imageIndex="1" link="products" title="Všechny produkty" count={counts.product} countDescription="produktů" />
          <Promo imageIndex="2" link="all-manufacturers" title="Všichni výrobci" count={counts.brand}countDescription="výrobců" />
          <Promo imageIndex="3" link="campaigns" title="Všechny kampaně" count={counts.campaign} countDescription="kampaní" />
        </div>

        <Search />

        <div className="products separate">
          {authContext.isAuthenticated && <SwiperProduct sliderID="slider1" badgeClass="campaign" />}
          {/* 
          <Swiper sliderID="slider1" badgeClass="campaign" />
          <Swiper sliderID="slider2" badgeClass="new" />
          <Swiper sliderID="slider3" badgeClass="campaign" />
          */}
        </div>

        <div className="separate">{authContext.isAuthenticated && <Manufacturers />}</div>
      </div>
    </>
  );
};

export default Homepage;

