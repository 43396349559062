import React from "react";
import { Typography, Button } from "@material-ui/core";
import ProductQuantity from "../Products/ProductQuantity";
import IconBasket from "../Icons/IconBasket";

const ToBasket = ({ price }) => {
  return (
    <div className="toBasket">
      <div className="price">
        <Typography component="h6" variant="h6">
          Cena: {price} €
        </Typography>
      </div>
      <ProductQuantity />
      <Button variant="contained" color="primary" className="productToCart" endIcon={<IconBasket color="white" />}>
        Vložit do košíku
      </Button>
    </div>
  );
};
export default ToBasket;
