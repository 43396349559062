import React from "react";
import { Switch, Route } from "react-router-dom";

import Login from "../components/Pages/Login";
import Homepage from "../components/Pages/Homepage";
import Products from "../components/Pages/Products";
import Detail from "../components/Pages/Detail";
import Campaigns from "../components/Pages/Campaigns";
import Cart from "../components/Pages/Cart";
import Orders from "../components/Pages/Orders";
import User from "../components/Pages/User";
import UserOriginal from "../components/Pages/UserOriginal";
import About from "../components/Pages/About";
import AllManufacturers from "../components/Pages/AllManufacturers";
import AllCampaigns from "../components/Pages/AllCampaigns";

import PrivateRoute from "./PrivateRoute";

const AppRoutes = (
  <Switch>
    <Route exact path="/login" component={Login} />

    <PrivateRoute exact path="/" component={Homepage} />
    <PrivateRoute exact path="/manufacturers" component={AllManufacturers} />    
    <PrivateRoute exact path="/products" component={Products} />
    <PrivateRoute exact path="/detail/:id" component={Detail} />
    <PrivateRoute exact path="/campaigns" component={AllCampaigns} />
    <PrivateRoute exact path="/campaigns/:id" component={Campaigns} />
    <PrivateRoute exact path="/cart" component={Cart} />
    <PrivateRoute exact path="/orders" component={Orders} />
    <PrivateRoute exact path="/user" component={User} />
    <PrivateRoute exact path="/user-original" component={UserOriginal} />
    <PrivateRoute exact path="/about" component={About} />
  </Switch>
);

export default AppRoutes;
