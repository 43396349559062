import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Box,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  IconButton,
  Chip,
  Typography,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import ProductQuantity from "./ProductQuantity";
import IconBasket from "../Icons/IconBasket";
import CartContext from "../../../context/Cart/CartContext";
import { toast } from "react-toastify";

export const Product = ({ data }) => {
  const cartContext = useContext(CartContext);
  const [amount, setAmount] = useState(1);

  //const [productLike, setProductLike] = useState({ saved: false });

  const chipLabel = "V kampani";
  const badge = "campaign";

  /*  
  if (props.badge === "campaign") {
    chipLabel = "V kampani";
  } else if (props.badge === "new") {
    chipLabel = "Novinka";
  }
  */

  /* const saveProduct = (event) => {
    setProductLike({ saved: !productLike });
  }; */

  const addToCart = () => {
    cartContext.addItem({
      product: data,
      amount: amount,
    });
    notify(amount);
    // reset amout
    setAmount(1);
  };
  const notify = (amount) =>
    toast.success(`Produkty přidány do košíku (${amount}).`, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  //  toast("Wow so easy !");
  return (
    <Box className="product" boxShadow={3}>
      {data && (
        <Card>
          <CardHeader
            className="productHeader"
            avatar={<Chip label={chipLabel} className={`productBadge ${badge}`}></Chip>}
            action={<FontAwesomeIcon icon={faHeart} /* onClick={(e) => saveProduct(e)} */ className={`productLike`} />}
          />
          <Link to={`/detail/${data.id}`}>
            <CardMedia className="productImage" image={data.image ? data.image : "default.jpg"} title="image" />
            <CardContent className="productBody">
              <Typography component="h2" className="productName">
                {data.name}
              </Typography>
              <div className="productOverview">
                <div>
                  <Typography component="h5">{data.brand_name}</Typography>
                  <Typography component="p">SUKL: {data.sukl}</Typography>
                </div>
                <div>
                  <div className="productPrice">{data.price} &euro;</div>
                </div>
              </div>
            </CardContent>
          </Link>
          <CardActions className="productFooter">
            <ProductQuantity quantity={amount} setQuantity={setAmount} />
            <IconButton className="productToCart" aria-label="decrement" onClick={addToCart}>
              <IconBasket color="white" />
            </IconButton>
          </CardActions>
        </Card>
      )}
    </Box>
  );
};

Product.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Product;
