import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Button, Badge } from "@material-ui/core";
import IconUser from "../UI/Icons/IconUser";
import IconSignOut from "../UI/Icons/IconSignOut";
import AuthContext from "../../context/Auth/AuthContext";

import Logo from "../../assets/images/logo-UI.svg";
import PharmacyButton from "../UI/Header/PharmacyButton";
import CartButton from "../UI/Header/CartButton";

const Header = (props) => {
  const authContext = useContext(AuthContext);

  return (
    <header className={`header${props.logedIn ? " logged" : ""}`}>
      <Link to="/">
        <img src={Logo} className="Logo" alt="Logo" />
      </Link>
      {props.logedIn ? (
        <ul className="menu">            
          <li>
            <PharmacyButton />              
          </li>
          <li className="divide">
            <Badge color="primary" badgeContent={5}>
              <Button variant="outlined" color="primary" component={Link} to="/orders">
                Objednávky
              </Button>
            </Badge>
          </li>
          <li>
            <CartButton />
          </li>
          <li className="divide">
            <Button variant="outlined" color="primary" component={Link} to="/user">
              {authContext.isAuthenticated && authContext.user.information.email}
              <IconUser className="iconUser" color="rgb(0, 129, 223)" />
            </Button>
          </li>
          <li>
            <Button variant="outlined" color="primary" onClick={authContext.logout}>
              <IconSignOut color="rgb(0, 129, 223)" />
            </Button>
          </li>
        </ul>
      ) : null}
    </header>
  );
};

export default Header;
