import React from "react";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

import "swiper/swiper-bundle.min.css";

import imgBig from "../../../assets/images/Produkt-big.png";

SwiperCore.use([Navigation]);

const SwiperDetail = ({ images }) => {
  images = [imgBig, imgBig, imgBig, imgBig];
  return (
    <div className="swiperContainer">
      <Swiper
        className="swiperDetail"
        spaceBetween={0}
        slidesPerView={1}
        loop={false}
        navigation={{
          nextEl: `.swiperNext`,
          prevEl: `.swiperPrev`,
        }}
        /* onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)} */
      >
        {images.map((item) => (
          <SwiperSlide>
            <img src={item} alt="slide" />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swiperNavigation">
        <div className="swiperNext">
          <FontAwesomeIcon icon={faAngleRight} />
        </div>
        <div className="swiperPrev">
          <FontAwesomeIcon icon={faAngleLeft} />
        </div>
      </div>
    </div>
  );
};
export default SwiperDetail;
