import {
  PRODUCT_SEARCH,
  PRODUCT_DETAIL,
  PRODUCT_ERROR,
  PRODUCT_ROWS_LOADING,
  PRODUCT_DETAIL_LOADING,
  PRODUCT_LIMIT,
  PRODUCT_PAGE,
  PRODUCT_FILTER,
  PRODUCT_SORT,
} from "../Types";

export default (state, action) => {
  switch (action.type) {
    case PRODUCT_ROWS_LOADING:
      return {
        ...state,
        loadingRows: true,
      };
    case PRODUCT_SEARCH:
      return {
        ...state,
        rows: action.payload.data,
        rowCount: action.payload.total,
        loadingRows: false,
      };
    case PRODUCT_DETAIL_LOADING:
      return {
        ...state,
        loadingDetail: true,
      };
    case PRODUCT_DETAIL:
      return {
        ...state,
        current: action.payload,
        loadingDetail: false,
      };
    case PRODUCT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case PRODUCT_LIMIT:
      return {
        ...state,
        limit: action.payload,
        page: 0,
      };
    case PRODUCT_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case PRODUCT_FILTER:
      return {
        ...state,
        searchProceed: true,
        filter: {
          ...state.filter,
          fulltext: action.payload.fulltext ? action.payload.fulltext : "",
          brandId: action.payload.brandId ? action.payload.brandId : "",
          priceFrom: action.payload.priceFrom ? action.payload.priceFrom : "",
          priceTo: action.payload.priceTo ? action.payload.priceTo : "",
        },
      };
    case PRODUCT_SORT:
      return {
        ...state,
        sortField: action.payload.field,
        sortDirection: action.payload.direction,
      };

    default:
      return state;
  }
};
