import React, { useEffect, useContext } from "react";
import { Typography } from "@material-ui/core";

import Search from "../UI/Search/Search";
import FilterProductDetail from "../UI/Filters/FilterProductDetail";
import SwiperDetail from "../UI/Swiper/SwiperDetail";
import ToBasket from "../UI/Detail/ToBasket";
import CampaignAction from "../UI/Detail/CampaignAction";
import Specification from "../UI/Detail/Specifications";
import Manufacturer from "../UI/Manufacturers/Manufacturer";
import OtherProducts from "../UI/Detail/OtherProducts";

import AuthContext from "../../context/Auth/AuthContext";
import ProductContext from "../../context/Product/ProductContext";

const Detail = ({ match }) => {
  const authContext = useContext(AuthContext);
  const productContext = useContext(ProductContext);

  useEffect(() => {
    if (authContext.isAuthenticated) {
      productContext.detail(match.params.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      {productContext.loadingDetail === false && productContext.current !== null && (
        <>
          <Search productDetail />
          <div className="productDetail">
            <Typography component="h4" variant="h4">
              {productContext.current.name}
            </Typography>
            <FilterProductDetail />
            <div className="main">
              <div className="left">
                <SwiperDetail images={productContext.current.image} />
                <div className="description">
                  <Typography component="h6" variant="h6">
                    Popis od výrobce:
                  </Typography>
                  <Typography component="p">
                    Jednoduché a velmi efektivní vyždímání mopu díky vylepšenému a zjednodušenému vkládání mopu do ždímacího koše a vyždímání díky rotačnímu systému poháněného
                    pouhým sešlápnutím pedálu. Díky pedálu není při ždímání potřeba žádné námahy. Dokonale vyždímaný mop pak zanechá vaší podlahu rychle suchou.
                    <br />
                    <br />
                    Návlek 2v1: 2 druhy vláken, které lépe odstraňují nečistoty a perfektně vyčistí vaší podlahu: Mikroaktivní vlákna pohltí a setřou mastnotu a šmouhy, abrazivní
                    vlákna: lépe odstraní i zaschlé nečistoty. 180° úhel: pro snadné dosažení i těch nejvíce obtížných oblastí jako pod postelí a nábytkem. Mycí návlek lze prát až
                    na 60 °C, nepoužívejte však aviváž ani sušičku.
                  </Typography>
                </div>
              </div>
              <div className="right">
                <Typography component="h6" variant="h6" className="mb">
                  Popis:
                </Typography>
                <Typography component="p">{productContext.current.description}</Typography>
                <ToBasket price={productContext.current.price} />
                <CampaignAction />
                <Specification />
                <Typography component="h6" variant="h6" className="space">
                  Výrobce
                </Typography>
                <Manufacturer />
              </div>
            </div>
            <OtherProducts />
          </div>
        </>
      )}
    </div>
  );
};
export default Detail;
