import React from "react";
import { Typography } from "@material-ui/core";
import image from "../../../assets/images/manufacturer.png";

const Manufacturer = ({ brand }) => {
  return (
    <div className="manufacturer">
      <img src={image} alt="manufacturer" />
      <div className="manufacturersLower">
        <Typography component="p">{brand.name}</Typography>
        <Typography component="p">879 produktů</Typography>
      </div>
    </div>
  );
};
export default Manufacturer;
