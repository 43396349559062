import React from "react";
import { Typography, Chip } from "@material-ui/core";

const FilterProductDetail = () => {
  return (
    <div className="filterProductDetail">
      <Typography component="h6">Kategorie:</Typography>
      <Chip label="Hartman" />
      <Chip label="Prevence" />
    </div>
  );
};
export default FilterProductDetail;
