import React from "react";
import { Button } from "@material-ui/core";
import IconEdit from "../Icons/IconEdit";
import IconTrash from "../Icons/IconTrash";
import IconPlus from "../Icons/IconPlus";

export default (props) => {
  const icon = {
    edit: <IconEdit color={props.iconColor} />,
    trash: <IconTrash color={props.iconColor} />,
    plus: <IconPlus color={props.iconColor} />,
  };

  const showIcon = icon[props.icon];
  /* let showIcon;
  if (icon === "edit") {
    showIcon = ;
  } */

  return (
    <Button
      {...(props.variant ? { variant: props.variant } : null)}
      {...(props.color ? { color: props.color } : null)}
      {...(props.onClick ? { onClick: props.onClick } : null)}
      {...(props.component ? { component: props.component } : null)}
      className={`button ${props.class ? props.class : ""}`}>
      {showIcon}
      {props.text}
    </Button>
  );
};
