import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import image from "../../../../../assets/images/Produkt-kampan.png";
import IconTrash from '../../../Icons/IconTrash';
import IconMinus from '../../../Icons/IconMinus';
import IconPlus from '../../../Icons/IconPlus';

const ProductList = ({ products, context }) => {
  const stock = 10;

   return (
    <TableContainer component={Paper} className="productListItem">
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Foto</TableCell>
            <TableCell align="left">Název</TableCell>
            <TableCell align="left">Sklad</TableCell>
            <TableCell align="left">Počet kusů</TableCell>
            <TableCell align="left">Rabat</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product) => {
            // correct product detail from basket
            if (product.product !== undefined) product = product.product;              
            // get item from cart
            const item = context.items.find(item => item.product.id === product.id)
            return (
            <Fragment key={product.id}>
              <TableRow className={`productItem`}>
                <TableCell align="left">
                  <div className="image">
                    <img src={image} alt="" />
                  </div>
                </TableCell>
  
                <TableCell align="left">
                  <Typography component="p">{product.name}</Typography>      
                </TableCell>
  
                <TableCell align="left">
                  <Typography component="p" className={`mr ${stock === 0 ?? "red"}`}>
                    {stock} ks
                  </Typography>
                </TableCell>      
  
                <TableCell align="left">
                  <div className="productQuantity">
                    <Button onClick={() => context.removeItem(product) }>
                      <IconMinus color="white" />
                    </Button>
                    <span className="quantity">
                      {item ? item.quantity : 0} KS
                    </span>
                    <Button aria-label="increment" onClick={() => context.addItem(product) }>
                      <IconPlus color="white" />
                    </Button>      
                  </div>
                </TableCell>

                <TableCell align="left">
                  <div className="productQuantity rabatButton">
                    <Button 
                      onClick={() => context.removeBonusItem(product) } 
                      className={item && item.bonus > 0 ? 'active' : ''}
                      >
                      <IconMinus color="white" />
                    </Button>
                    <span className="quantity">
                      {item ? item.bonus : 0} KS
                    </span>
                    <Button aria-label="increment" onClick={() => context.addBonusItem(product) } className={context.availableBonus > 0 ? 'active' : ''}>
                      <IconPlus color="white" />
                    </Button>      
                  </div>
                </TableCell>
  
                <TableCell align="left">
                  <Button variant="contained" className="mr">produkt</Button>
                </TableCell>
  
                <TableCell align="right">
                  <Button variant="contained" className="erase mr">
                    <IconTrash color="#999999" />
                  </Button>
                </TableCell>
  
              </TableRow>
              <tr style={{ height: "10px", boxShadow: "none" }} />
            </Fragment>
            )}
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
  context: PropTypes.object.isRequired,
}

export default ProductList
