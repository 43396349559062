import React /*,  { useState, useEffect, useContext } */ from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import AuthState from "./context/Auth/AuthState";
import ProductState from "./context/Product/ProductState";
import BrandState from "./context/Brand/BrandState";
import CartState from "./context/Cart/CartState";
import AppRoutes from "./routes/AppRoutes";

import "./styles/index.scss";
import CampaignState from "./context/Campaign/CampaignState";


const App = () => {
  return (
    <AuthState>
      <CartState>
        <ProductState>
          <BrandState>
            <CampaignState>
              <Router>
                <Layout>{AppRoutes}</Layout>
              </Router>
            </CampaignState>
          </BrandState>
        </ProductState>
      </CartState>
    </AuthState>
  );
};

export default App;
