import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Typography, InputBase, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const Search = (props) => {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };

  return (
    <div className="search">
      <div className="searchHead">
        <Typography component="h5">Vyhledávání</Typography>
        {props.productDetail ? (
          <Button onClick={goBack} variant="contained">
            zpět
          </Button>
        ) : null}
      </div>
      <div className="searchField">
        <div className="searchIcon">
          <FontAwesomeIcon icon={faSearch} />
        </div>
        <InputBase id="Search" type="search" placeholder="Hledat ve všech produktech" inputProps={{ "aria-label": "search" }} />
      </div>
      <div className="mostVisited">
        <Typography component="p">Často hledáno:</Typography>
        <ul>
          <li>
            <Link to="/">mast</Link>
          </li>
          <li>
            <Link to="/">bylinná zubná pasta</Link>
          </li>
          <li>
            <Link to="/">čistící pleťová maska</Link>
          </li>
          <li>
            <Link to="/">koflet-H Ginger</Link>
          </li>
          <li>
            <Link to="/">cistone tbl 1x100</Link>
          </li>
          <li>
            <Link to="/">krém na ruce</Link>
          </li>
          <li>
            <Link to="/">ústenka</Link>
          </li>
          <li>
            <Link to="/">ffp3</Link>
          </li>
          <li>
            <Link to="/">revitalizační krém</Link>
          </li>
          <li>
            <Link to="/">vitamín D</Link>
          </li>
          <li>
            <Link to="/">Bayer</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Search;
