import React from "react";

import { Table, TableBody, TableCell, TableContainer, TableHead, Paper, TableRow } from "@material-ui/core";
import OrderItem from "./OrderItem";

//import { default as Button } from "../Button/ButtonCustom";

const Order = (props) => {
  return (
    <TableContainer component={Paper} className="order">
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left" colspan="2">
              Číslo objednávky
            </TableCell>
            <TableCell align="left" colspan="4">
              Lékárna
            </TableCell>
            <TableCell align="left" colspan="3">
              Stav
            </TableCell>
            <TableCell align="left" colspan="2">
              Datum
            </TableCell>
            <TableCell align="left" colspan="2">
              Celková částka
            </TableCell>
            <TableCell align="left" colspan="2">
              Bonus
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <OrderItem processing />
          <tr style={{ height: "10px", boxShadow: "none" }} />
          <OrderItem processing orderClass="uncovered" />
          <tr style={{ height: "10px", boxShadow: "none" }} />
          <OrderItem processing orderClass="covered" />
          <tr style={{ height: "10px", boxShadow: "none" }} />
          <OrderItem processing orderClass="partialy" />
          <tr style={{ height: "10px", boxShadow: "none" }} />
          <OrderItem />
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default Order;
