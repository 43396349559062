import {
  CAMPAIGN_LIST,
  CAMPAIGN_DETAIL,
  CAMPAIGN_ERROR,
  CAMPAIGN_ROWS_LOADING,
  CAMPAIGN_DETAIL_LOADING,
  CAMPAIGN_LIMIT,
  CAMPAIGN_PAGE,
  CAMPAIGN_FILTER,
  CAMPAIGN_SORT,
} from "../Types";

export default (state, action) => {
  switch (action.type) {
    case CAMPAIGN_ROWS_LOADING:
      return {
        ...state,
        loadingRows: true
      };
    case CAMPAIGN_LIST:
      return {
        ...state,
        rows: action.payload.data,
        loadingRows: false
      };
    case CAMPAIGN_DETAIL_LOADING:
      return {
        ...state,        
        loadingDetail: true
      };
    case CAMPAIGN_DETAIL:
      return {
        ...state,
        current: action.payload.data,
        loadingDetail: false
      };
    case CAMPAIGN_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case CAMPAIGN_LIMIT:
      return {
        ...state,
        limit: action.payload,
        page: 0
      };
    case CAMPAIGN_PAGE:
      return {
        ...state,
        page: action.payload
      };
    case CAMPAIGN_FILTER:
      return {
        ...state,
        searchProceed: true,
        filter: {
          fulltext: action.payload.fulltext ? action.payload.fulltext : "",
          causeCode: action.payload.causeCode ? action.payload.causeCode : "",          
          applicant: action.payload.applicant ? action.payload.applicant : ""
        }
      };
    case CAMPAIGN_SORT:
      return {
        ...state,
        sortField: action.payload.field,
        sortDirection: action.payload.direction
      };
    
    default:
      return state;
  }
};
