import React, { useReducer, useEffect, useContext } from "react";
import axios from "axios";
import CartContext from "../Cart/CartContext";
import AuthContext from "../Auth/AuthContext";

import CampaignContext from "./CampaignContext";
import CampaignReducer from "./CampaignReducer";

import { 
  CAMPAIGN_DETAIL, 
  CAMPAIGN_DETAIL_LOADING, 
  CAMPAIGN_ERROR, 
  CAMPAIGN_FILTER, 
  CAMPAIGN_LIMIT, 
  CAMPAIGN_PAGE, 
  CAMPAIGN_ROWS_LOADING, 
  CAMPAIGN_LIST, 
  CAMPAIGN_SORT 
} from "../Types";


const CampaignState = (props) => {
  const cartContext = useContext(CartContext);
  const authContext = useContext(AuthContext);
  
  const initialState = {
    current: null,
    rows: [],
    error: null,
    loadingRows: true,
    loadingDetail: true,
    limitOptions: [10, 25, 50, 100],
    limit: 12,
    rowCount: 0,
    page: 0,
    sortField: "name",
    sortDirection: "asc",
    filter: {
      fulltext: "",
      brandId: "",
      priceFrom: "",
      priceTo: "",
    },
    filterList: [],
  };

  const [state, dispatch] = useReducer(CampaignReducer, initialState);

  useEffect(
    () => {      
      if (!authContext.loading && authContext.isAuthenticated) {
        load();
      }      
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cartContext.pharmacy, state.limit, state.page, state.filter, state.sortField, state.sortDirection]
  );

  // Set Loading
  const setRowsLoading = () => dispatch({ type: CAMPAIGN_ROWS_LOADING });
  const setDetailLoading = () => dispatch({ type: CAMPAIGN_DETAIL_LOADING });

  // Search
  const load = async () => {
    setRowsLoading();
    try {
      var filterParams = {
        pageSize: state.limit,
        pageNumber: state.page,
        sortField: state.sortField,
        sortDirection: state.sortDirection,
      };
      // eslint-disable-next-line array-callback-return
      Object.entries(state.filter).map(function (el) {
        if (el[1] !== "") {
          filterParams["filter_" + el[0].toLowerCase()] = el[1];
        }
      });
      const res = await axios.get(`/pharmacy/${cartContext.pharmacy.info.id}/campaign`, {
        params: filterParams,
      });
      dispatch({
        type: CAMPAIGN_LIST,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: CAMPAIGN_ERROR,
        payload: err,
      });
    }
  };

  const detail = async ( campaignId) => {
    setDetailLoading();
    if (cartContext.pharmacy !== null) {
      try {
        const res = await axios.get(`/pharmacy/${cartContext.pharmacy.info.id}/campaign/${campaignId}`);
        dispatch({
          type: CAMPAIGN_DETAIL,
          payload: res.data,
        });
      } catch (err) {
        dispatch({
          type: CAMPAIGN_ERROR,
          payload: err,
        });
      }
    }
  };

  const changeRowsPerPage = (rowCount) => {
    return dispatch({
      type: CAMPAIGN_LIMIT,
      payload: rowCount,
    });
  };

  const changePage = (pageIndex) => {
    return dispatch({
      type: CAMPAIGN_PAGE,
      payload: pageIndex,
    });
  };

  const setFilter = (filter) => {
    return dispatch({
      type: CAMPAIGN_FILTER,
      payload: filter,
    });
  };

  const setSort = (field, direction) => {
    return dispatch({
      type: CAMPAIGN_SORT,
      payload: {
        field: field,
        direction: direction,
      },
    });
  };

  return (
    <CampaignContext.Provider
      value={{
        loadingRows: state.loadingRows,
        loadingDetail: state.loadingDetail,
        loadingUserList: state.loadingUserList,
        searchProceed: state.searchProceed,
        current: state.current,
        rows: state.rows,
        error: state.error,
        limitOptions: state.limitOptions,
        limit: state.limit,
        rowCount: state.rowCount,
        page: state.page,
        sortField: state.sortField,
        sortDirection: state.sortDirection,
        filter: state.filter,
        filterList: state.filterList,

        load,
        detail,
        changeRowsPerPage,
        changePage,
        setFilter,
        setSort,
      }}
    >
      {props.children}
    </CampaignContext.Provider>
  );
};

export default CampaignState;
