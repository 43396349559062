import React, { useContext } from "react";
import PropTypes from 'prop-types'
import { TextField, MenuItem } from "@material-ui/core";
import IconCheck from "../Icons/IconCheck";
import CartContext from "../../../context/Cart/CartContext";

const SupplierSelect = ({ distributorCode, setDistributorCode }) => {
  const cartContext = useContext(CartContext);
  
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const handleChange = (event) => {
    setDistributorCode(event.target.value);
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  return (
    <TextField id="select-supplier" className="selectSupplier" select variant="outlined" label="Dodavatel" value={distributorCode} onChange={handleChange}>
      {cartContext.pharmacy.distributor_code.map((code, index) => (
        <MenuItem key={code.id} value={code.id} className={selectedIndex === index ? "selected" : null} onClick={(event) => handleListItemClick(event, index)}>
          {code.distributor.name} - {code.info_code}
          {selectedIndex === index ? <IconCheck color="#fff" style={{ width: "20px" }} /> : null}
        </MenuItem>
      ))}
    </TextField>
  );
};

SupplierSelect.propType = {
  distributorCode: PropTypes.number.isRequired,
  setDistributorCode: PropTypes.func.isRequired,
}

export default SupplierSelect;
