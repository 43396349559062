import React, { useState } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const Datepicker = (props) => {
  const [selectedDate, handleDateChange] = useState(new Date());
  let label;
  if (props.label === "from") {
    label = "od";
  } else if (props.label === "to") {
    label = "do";
  }
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker disableToolbar variant="inline" inputVariant="outlined" label={label} value={selectedDate} onChange={handleDateChange} />
    </MuiPickersUtilsProvider>
  );
};
export default Datepicker;
