import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import image from "../../../../../assets/images/Produkt-kampan.png";
import IconTrash from '../../../Icons/IconTrash';
import IconMinus from '../../../Icons/IconMinus';
import IconPlus from '../../../Icons/IconPlus';

const ProductList = ({ products, context }) => {
  const stock = 10;

   return (
    <TableContainer component={Paper} className="productListItem">
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Foto</TableCell>
            <TableCell align="left">Název</TableCell>
            <TableCell align="left">Sklad</TableCell>
            <TableCell align="left">Počet kusů</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product) => {
            // correct product detail from basket
            if (product.product !== undefined) product = product.product;
            return (
            <Fragment key={product.id}>
              <TableRow className={`productItem`}>
                <TableCell align="left">
                  <div className="image">
                    <img src={image} alt="" />
                  </div>
                </TableCell>
  
                <TableCell align="left">
                  <Typography component="p">{product.name}</Typography>      
                </TableCell>
  
                <TableCell align="left">
                  <Typography component="p" className={`mr ${stock === 0 ?? "red"}`}>
                    {stock} ks
                  </Typography>
                </TableCell>      
  
                <TableCell align="left">
                  <div className="productQuantity">
                    <Button onClick={() => context.removeItem(product) }>
                      <IconMinus color="white" />
                    </Button>
                    <span className="quantity">
                      {context.items.findIndex(item => item.product.id === product.id) < 0 ? 0 : 
                        context.items[context.items.findIndex(item => item.product.id === product.id)].quantity} KS
                    </span>
                    <Button aria-label="increment" onClick={() => context.addItem(product) }>
                      <IconPlus color="white" />
                    </Button>      
                  </div>
                </TableCell>
  
                <TableCell align="left">
                  <Button variant="contained" className="mr">produkt</Button>
                </TableCell>
  
                <TableCell align="right">
                  <Button variant="contained" className="erase mr">
                    <IconTrash color="#999999" />
                  </Button>
                </TableCell>
  
              </TableRow>
              <tr style={{ height: "10px", boxShadow: "none" }} />
            </Fragment>
            )}
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
  context: PropTypes.object.isRequired,
}

export default ProductList
