import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS
} from "../Types";
import setAuthToken from "../../utility/setAuthToken";

export default (state, action) => {
  switch (action.type) {
    case USER_LOADED:
      if (action.payload) {
        localStorage.setItem("jwtToken", action.payload.access_token);
        setAuthToken(action.payload.access_token);
        return {
          ...state,
          isAuthenticated: true,
          loading: false,
          token: action.payload.access_token,
          user: action.payload
        };
      }
      else {
        return {
          ...state,
          isAuthenticated: false,
          loading: false,
          token: null,
          user: null
        }
      }
    case LOGIN_SUCCESS:
      localStorage.setItem("jwtToken", action.payload.access_token);
      setAuthToken(action.payload.access_token);
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        token: action.payload.access_token,
        user: action.payload
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem("jwtToken");
      setAuthToken(false);
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
        error: action.payload
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
};
