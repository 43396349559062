import React from "react";
import PropTypes from "prop-types";
import { Typography, TableContainer, Table, TableBody, TableCell, TableRow, Paper, Popover, Backdrop } from "@material-ui/core";
import Button from "../Button/Button";


const PharmacyItem = ({ data, selected }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <TableContainer component={Paper} className="pharmacyItem">
      <Table aria-label="simple table" className={(selected ? "selectedItem" : "") + (data.info.status_id === 260 ? "deletedItem" : "")}>
        <TableBody>
          <TableRow>
            <TableCell align="left">{data.info.name}</TableCell>
            <TableCell align="left">{data.info.street}, {data.info.city}, {data.info.postcode}</TableCell>
            <TableCell align="left" className="access">
              {(data.info.status_id === 220) && <span>Přístup umožněn</span>}
            </TableCell>

            {(data.status_id === 260)? (
              <TableCell align="right" className="deleted">
                ČEKÁ NA SMAZÁNÍ
              </TableCell>
            ) : (
              <>
                <TableCell align="right">
                  <Button onClick="" text="upravit" class="orange icon iconSeparate" variant="contained" icon="edit" iconColor="white" />
                </TableCell>
                <TableCell align="right" className={selected ? "selected" : ""}>
                  {selected ? (
                    "VYBRANÁ"
                  ) : (
                    <>
                      <Button text="odstranit" class="red icon iconSeparate" variant="contained" icon="trash" iconColor="white" onClick={handleClick} />{" "}
                      <div style={{ position: "relative", zIndex: "1000" }}>
                        <Backdrop open={open} onClick={handleClose}>
                          <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}>
                            <Typography component="p">Odstranit lékárnu</Typography>
                            <Typography component="p" className="filterName">
                              Opravdu chcete požádat o odstranění lékárny?
                            </Typography>
                            <div className="filterButtonGroup pharmacyFilterGroup">
                              <Button variant="contained" onClick={handleClose} text="zrušit" />
                              <Button variant="contained" class="red" text="odstranit" />
                            </div>
                          </Popover>
                        </Backdrop>
                      </div>
                    </>
                  )}
                </TableCell>
              </>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

PharmacyItem.propTypes = {
  data: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
}
export default PharmacyItem;
